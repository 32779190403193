import React, { useState, useRef, useEffect } from 'react';
import { Validaciones, Encriptar } from '@renedelangel/helpers';

//Redux
import { useSelector } from 'react-redux';

//master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
//componentes genericos
import SweetAlert from '_layout/genericComponents/ModalConfirmacion';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

//iconos
import SyncIcon from '@material-ui/icons/Sync';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

//Queries
import { getSolSegFianEquipoOper, getAllSolSegFianEquipoOper, seleccionSolSegFianEquipoOper, tablaSolSegFianEquipoOper } from '../../../querys/Solsegfian_equipooper';

import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionActivosAsegurado } from '../../../querys/ActivosAsegurado';
import { seleccionUbicaciones } from '../../../querys/Ubicaciones';
import { seleccionActivosAsegurado as seleccionActivosAseguradosSolPol, getInfoActAsegPolizas } from '../PolizasSeguro/ConsultasActivos';
import { getPolizasSeguro } from '../../../querys/PolizasSeguro';

//Mutations
import { addSolSegFianEquipoOper, updateSolSegFianEquipoOper, deleteSolSegFianEquipoOper } from '../../../mutations/Solsegfian_equipooper';

//HANDLERS
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../../_layout/helpers/handles';
import { rgxdeInt11, rgxdeInt4, rgxInputTexto30, rgxInputTexto10, rgxInputTexto20, rgxInputTexto50, rgxNumeros, rgxDecimal14 } from '../../../helpers/regexp';

//COLORES
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import { handleChange } from 'helpers/rutinas/metodos/rutinasProductos';
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';
import {  toFixed,redondeo } from '../../../helpers/index';

import moment from 'moment';
function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }
const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;
function SolSegFianEquipoOper({ datosDetalle, setOpenSolSegEquipoOperacion, lMostrarFormularioCompleto }) {

    const objectAnexos = {
        "Equipo de Contratistas y Maq. Pesada" : "ANEXOS: Alta: Factura de equipo, OC y gastos de flete. Especificaciones técnicas. Fotografía del equipo y serie. Indicar el equipo que sustituye y en correo informar si ya se encuentra fuera de servicio. Baja: Factura y OC de Equipo, LIBRO DE BAJA firmado y sus motivos de baja.",
        "Flotilla de automóviles (Camiones Carga General)" : "ANEXOS: Alta: Factura de equipo, OC y gastos de flete. Especificaciones técnicas. Fotografía del equipo y serie. Indicar el equipo que sustituye y en correo informar si ya se encuentra fuera de servicio. Baja: Factura y OC de Equipo, LIBRO DE BAJA firmado y sus motivos de baja.",
        "Múltiple Empresarial- Rotura" : "ANEXOS: Alta: Factura de equipo, OC y gastos de flete. Especificaciones técnicas. Fotografía del equipo y serie. Indicar el equipo que sustituye y en correo informar si ya se encuentra fuera de servicio. Baja: Factura y OC de Equipo, LIBRO DE BAJA firmado y sus motivos de baja."
    };
    let now = moment();
    let formato = "YYYY-MM-DD";

    let history = useHistory();
    const { token } = useSelector(state => state.login);
    let lUtilizaProps = true;
    let URLSolSegFianID = null;
    let URLPolizasSeguroID = null;
    let mostrarTabla = false;
    let nEquipoOperID = null;
    let nEmpresaID = null;
    let nRamoSeguroID = null;
    let nSubRamoID = null;
    let nRamoSeguro = null;
    let nUsuarioSolicita = null;
    let datosDetalleURL = {};
    let cfolioFormateado = null;
    let cEstatus = null;
    let nSolicitud = null;
    let URLSubPolizaID = null;
    let pSeleccionTipoPoliza = null;
    let cTitulo2 = objectAnexos[datosDetalle.ramoSeguro] ?? "ANEXOS: Alta: Factura de equipo, OC y gastos de flete. Especificaciones técnicas. Fotografía del equipo y serie. Indicar el equipo que sustituye y en correo informar si ya se encuentra fuera de servicio. Baja:  Factura y OC de Equipo  y sus motivos de baja.";
    let { params } = useParams();
    if (params.length === 0) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { pURLSolSegFianID, pEmpresaID, pRamoSeguroID, pUsuarioSolicita, datosDetalle, pfolioFormateado, pURLPolizasSeguroID, pSolicitud ,pSubpolizaID,seleccionTipoPoliza, ramoSeguro,pSubRamoID} = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        URLSolSegFianID = pURLSolSegFianID;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        datosDetalleURL = datosDetalle;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        nRamoSeguroID = pRamoSeguroID;
        nSubRamoID = pSubRamoID;
        nRamoSeguro = ramoSeguro;
        nSolicitud = pSolicitud;
        URLSubPolizaID = pSubpolizaID;
        pSeleccionTipoPoliza = seleccionTipoPoliza;
    }
    let { onCerrarModal, onOcultarTabla } = datosDetalleURL;
    mostrarTabla = URLSolSegFianID ? true : false;

    if (datosDetalleURL != undefined && datosDetalleURL.length > 0) {
        lUtilizaProps = false;
    }

    if (lUtilizaProps) {
        let { pEstatus, pSolSegFianID, pEquipoOperID, pEmpresaID, pRamoSeguroID, pUsuarioSolicita, onCerrarModal, mostrarTabla, onOcultarTabla, pfolioFormateado, pURLPolizasSeguroID, pSolicitud ,pSubpolizaID,seleccionTipoPoliza, ramoSeguro,pSubRamoID} = datosDetalle;

        URLSolSegFianID = pSolSegFianID;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        mostrarTabla = mostrarTabla;
        nEquipoOperID = pEquipoOperID;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        cEstatus = pEstatus;
        nSolicitud = pSolicitud;
        nRamoSeguroID = pRamoSeguroID;
        nSubRamoID = pSubRamoID;
        nRamoSeguro = ramoSeguro;
        URLSubPolizaID = pSubpolizaID;
        pSeleccionTipoPoliza = seleccionTipoPoliza;

    }
    mostrarTabla = lMostrarFormularioCompleto;

    const cleanState = {
        equipoOperID: nEquipoOperID,
        solSegFianID: URLSolSegFianID,
        activoAsegID: "0",
        equipo: "",
        noEco: "",
        marca: "",
        modelo: "",
        anio: "",
        serie: "",
        capacidad: "",
        empresaID: nEmpresaID,
        ubicacionID: null,
        valorAseg: "",
        sustituye: "",
        motivo: "",
        sustituyeID: 0,
        omitir: 0,
        divisaID: datosDetalle.codigoMoneda === "USD" ? 100 : 69,
        paridad: "1",
        activo: "ACTIVO",
        modeloCorrecto: "",
        montoAsegurado:""
    };

    const cleanErrorState =
    {
        equipoOperID: { error: false, helperText: "" },
        solSegFianID: { error: false, helperText: "" },
        activoAsegID: { error: false, helperText: "" },
        equipo: { error: false, helperText: "" },
        noEco: { error: false, helperText: "" },
        marca: { error: false, helperText: "" },
        modelo: { error: false, helperText: "" },
        anio: { error: false, helperText: "" },
        serie: { error: false, helperText: "" },
        capacidad: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        ubicacionID: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        ubicacionID: { error: false, helperText: "" },
        valorAseg: { error: false, helperText: "" },
        sustituye: { error: false, helperText: "" },
        motivo: { error: false, helperText: "" },
        sustituyeID: { error: false, helperText: "" },
        omitir: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        activo: { error: false, helperText: "" },
        modeloCorrecto: { error: false, helperText: "" },
        montoAsegurado: { error: false, helperText: "" }
    };

    const cleanNotificaciones = { mensajes: "", color: null, open: false };
    //Seleccionables
    const [disabledActivo, setDisabledActivo] = useState(false);
    const [seleccionables, setSeleccionables] = useState({});
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(!mostrarTabla);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    let titleAccion = state.equipoOperID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Equipo de Operación ${cfolioFormateado}`;

    const activoAsegIDRef = useRef(null);
    const equipoRef = useRef(null);
    const noEcoRef = useRef(null);
    const marcaRef = useRef(null);
    const modeloRef = useRef(null);
    const anioRef = useRef(null);
    const serieRef = useRef(null);
    const capacidadRef = useRef(null);
    const valorAsegRef = useRef(null);
    const sustituyeRef = useRef(null);
    const motivoRef = useRef(null);
    const empresaIDRef = useRef(null);
    const ubicacionIDRef = useRef(null);
    const sustituyeIDRef = useRef(null);
    const divisaIDRef = useRef(null);
    const modeloCorrectoRef = useRef(null);
    const montoAseguradoRef = useRef(null);

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    let acciones = [
        {
            accionID: [1, 3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "equipoOperID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getSolSegFianEquipoOper, formatearResultado: [
                    { id: "activo", formato: (data) => data === null ? "" : data },
                ]
            })
        },
        {
            accionID: [4],
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "equipoOperID" },
            { campo: "equipo" }],
            disabled: { multiseleccion: true },
            onClick: ({ equipoOperID, equipo }) => handelAlertEliminar({
                setAlert,
                mensaje: `El equipo de operacion ${equipo}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { equipoOperID },
                    asyncDelete: deleteSolSegFianEquipoOper
                })
            })
        },
        {
            accionID: [7],
            icono: UnarchiveIcon,
            color: info,
            descripcion: "Adjuntar",
            parametros: [{ campo: "equipoOperID" }],
            disabled: { multiseleccion: true },
            onClick: ({ equipoOperID }) => {
                // setRedireccionarFormulario({ equipoOperID });
                setDatosDetalleAdjuntos({
                    pURLDoctoOrigenID: equipoOperID,
                    pTipo: cfolioFormateado,
                    pClasificacion: "",
                    pTabla: "Solicitud Póliza de Equipo de Operación",
                    pTitulo2: cTitulo2,
                    plUtilizaProps: true
                });
                setOpenDocumentacion(true);

            }
        }
    ];

    let botones = [{
        icono: BackspaceOutlined,
        color: danger,
        descripcion: "Regresar",
        disabled: { multiseleccion: true },
        onClick: () => {
            //  history.push(`/gen/SolicitudesSeguros/`);
            setOpenSolSegEquipoOperacion();
        }
    },
    {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }
    ];

    
    if (cEstatus != "RECHAZADA"){
        botones = [...botones, {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {
                setOpen(true); 
                divisas()
            },
            disabled: { multiseleccion: true }
        }]
    }


    let arrayHeaderPrincipal = [
        { variable: "equipoOperID", descripcion: "ID", hide: true },
        { variable: "marca", descripcion: "Marca", hide: false },
        { variable: "noEco", descripcion: "Número Economico", hide: false },
        { variable: "modelo", descripcion: "Modelo", hide: false },
        { variable: "anio", descripcion: "Año", hide: false },
        { variable: "serie", descripcion: "Número de Serie", hide: false },
        { variable: "capacidad", descripcion: "Capacidad", hide: false }
    ]

    let arrayEquipoContratista = [ 
        { variable: "equipoOperID", descripcion: "ID", hide: true },
        { variable: "equipo", descripcion: "Equipo", hide: false },
        { variable: "noEco", descripcion: "Número Economico", hide: false },
        { variable: "modelo", descripcion: "Modelo", hide: false },
        { variable: "marca", descripcion: "Marca", hide: false },
        { variable: "anio", descripcion: "Año", hide: false },
        { variable: "serie", descripcion: "Número de Serie", hide: false },
        { variable: "capacidad", descripcion: "Capacidad", hide: false },
        { variable: "valorAseg", descripcion: "Valor Factura", hide: false },  // se cambio de Valor Asegurado a Valor Factura
        { variable: "divisa", descripcion: "Divisa", hide: false },
        { variable: "montoAsegurado", descripcion: "Monto Asegurado", hide: false },
        { variable: "sustituye", descripcion: "Sustituye", hide: false },
        { variable: "activoSustituye", descripcion: nSolicitud === "Baja de Seguro" ? "Baja a" : "Activo a Sustituir", hide: false },
        { variable: "motivo", descripcion: "Motivo", hide: false },
    ]
   
    let arrayFlotillaAuto = [ 
        { variable: "equipoOperID", descripcion: "ID", hide: true },
        { variable: "equipo", descripcion: "Equipo", hide: false },
        { variable: "noEco", descripcion: "Número Economico", hide: false },
        { variable: "modelo", descripcion: "Modelo", hide: false },
        { variable: "marca", descripcion: "Marca", hide: false },
        { variable: "anio", descripcion: "Año", hide: false },
        { variable: "serie", descripcion: "Número de Serie", hide: false },
        { variable: "capacidad", descripcion: "Capacidad", hide: false },
        { variable: "valorAseg", descripcion: "Valor Factura", hide: false }, 
        { variable: "divisa", descripcion: "Divisa", hide: false },
        { variable: "montoAsegurado", descripcion: "Monto Asegurado", hide: false },
        { variable: "activoSustituye", descripcion: nSolicitud === "Baja de Seguro" ? "Baja a" : "Activo a Sustituir", hide: false },
    ]
       
    let arrayMultipleEmpreRotura = [ 
        { variable: "equipoOperID", descripcion: "ID", hide: true },
        { variable: "equipo", descripcion: "Equipo", hide: false },
        { variable: "noEco", descripcion: "Número Economico", hide: false },
        { variable: "modelo", descripcion: "Modelo", hide: false },
        { variable: "marca", descripcion: "Marca", hide: false },
        { variable: "anio", descripcion: "Año", hide: false },
        { variable: "serie", descripcion: "Número de Serie", hide: false },
        { variable: "capacidad", descripcion: "Capacidad", hide: false },
        { variable: "valorAseg", descripcion: "Valor Factura", hide: false }, 
        { variable: "divisa", descripcion: "Divisa", hide: false },
        { variable: "montoAsegurado", descripcion: "Monto Asegurado", hide: false },
        { variable: "activoSustituye", descripcion: nSolicitud === "Baja de Seguro" ? "Baja a" : "Activo a Sustituir", hide: false },
    ]

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "equipoOperID",
        color: primary,
        title: `Solicitud de Equipo de Operación ${cfolioFormateado}` + nRamoSeguroID,
        title2: cTitulo2,
        iconTable: <BusinessIcon />,
        headers: nRamoSeguro === "Equipo de Contratistas y Maq. Pesada" ? arrayEquipoContratista : (nRamoSeguro === "Flotilla de automóviles (Camiones Carga General)" ? arrayFlotillaAuto : (nRamoSeguro === "Múltiple Empresarial- Rotura"  ? arrayMultipleEmpreRotura : arrayHeaderPrincipal)),
        responsiveTitle: ["equipo", "noEco","modelo"],
        formato: [
            {
                columnas: ["valorAseg","montoAsegurado"], tipo: "moneda"
            },
        ],
    }

    const inputs = [
        {
            disabled: InputsDisabled(),
            id: "activoAsegID",
            value: state.activoAsegID,
            error: errorState.activoAsegID.error,
            success: state.activoAsegID && !errorState.activoAsegID.error ? true : undefined,
            helperText: errorState.activoAsegID.helperText,
            inputRef: activoAsegIDRef,
            title: "Activo Asegurado *",
            placeholder: "Seleccione el Activo Asegurado",
            tipo: "autocomplete",
            data: seleccionables.ActivoAseg,
            grid: { md: 4, lg: 4 },
            onChange: ({ value, label }) => { setState({ ...state, activoAsegID: value, activoaseg: label }) }
        },
        {
            disabled:true,
            id: "empresaID",
            value: state.empresaID,
            error: errorState.empresaID.error,
            success: state.empresaID && !errorState.empresaID.error ? true : undefined,
            helperText: errorState.empresaID.helperText,
            inputRef: empresaIDRef,
            title: "Empresa *",
            placeholder: "Seleccione la empresa",
            tipo: "autocomplete",
            data: seleccionables.Empresa,
            grid: { md: 4, lg: 4 },
            onChange: ({ value, label }) => { setState({ ...state, empresaID: value, nombre: label }) }
        },
        {
            disabled: InputsDisabled(),
            id: "ubicacionID",
            value: state.ubicacionID,
            error: errorState.ubicacionID.error,
            success: state.ubicacionID && !errorState.ubicacionID.error ? true : undefined,
            helperText: errorState.ubicacionID.helperText,
            inputRef: ubicacionIDRef,
            title: "Ubicación *",
            placeholder: "Seleccione la ubicación de la empresa",
            tipo: "autocomplete",
            data: seleccionables.Ubicaciones,
            grid: { md: 4, lg: 4 },
            onChange: ({ value, label }) => { setState({ ...state, ubicacionID: value, ubicacion: label }) }
        },
        {
            disabled: InputsDisabled(),
            id: "equipo",
            value: state.equipo,
            error: errorState.equipo.error,
            success: state.equipo && !errorState.equipo.error ? true : undefined,
            helperText: errorState.equipo.helperText,
            inputRef: equipoRef,
            title: "Equipo",
            grid: { md: 6, lg: 6 },
            placeholder: "Introduzca el nombre del equipo",
            onChange: ({ target: { value } }) => handleChange(value, "equipo"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "noEco",
            value: state.noEco,
            error: errorState.noEco.error,
            success: state.noEco && !errorState.noEco.error ? true : undefined,
            helperText: errorState.noEco.helperText,
            inputRef: noEcoRef,
            title: "Número Económico *",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca el económico del equipo",
            onChange: ({ target: { value } }) => handleChange(value, "noEco"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "marca",
            value: state.marca,
            error: errorState.marca.error,
            success: state.marca && !errorState.marca.error ? true : undefined,
            helperText: errorState.marca.helperText,
            inputRef: marcaRef,
            title: "Marca *",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca la marca",
            onChange: ({ target: { value } }) => handleChange(value, "marca"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "modelo",
            value: state.modelo,
            error: errorState.modelo.error,
            success: state.modelo && !errorState.modelo.error ? true : undefined,
            helperText: errorState.modelo.helperText,
            inputRef: modeloRef,
            title: "Modelo *",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca el modelo",
            onChange: ({ target: { value } }) => handleChange(value, "modelo"),
            onKeyDown: () => { return; }
        },
        /*{
            disabled: InputsDisabled(),
            id: "equipo",
            value: state.equipo,
            error: errorState.equipo.error,
            success: state.equipo && !errorState.equipo.error ? true : undefined,
            helperText: errorState.equipo.helperText,
            inputRef: equipoRef,
            title: "Número de equipo",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca el número del equipo",
            onChange: ({ target: { value } }) => handleChange(value, "equipo"),
            onKeyDown: () => { return; }
        },*/
        {
            disabled: InputsDisabled(),
            id: "anio",
            value: state.anio,
            error: errorState.anio.error,
            success: state.anio && !errorState.anio.error ? true : undefined,
            helperText: errorState.anio.helperText,
            inputRef: anioRef,
            title: "Año *",
            placeholder: "Introduzca el año",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => {
                (rgxdeInt4.test(value)) &&
                    handleChange(value, "anio")
            },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, anio: { error: state.anio.length > 5 && true, helperText: state.anio.length > 5 ? "Límite de 5 caracteres excedido" : "" } })
            },
            onKeyDown: (evt) => handleFocus(evt, serieRef)
        },
        {
            disabled: InputsDisabled(),
            id: "serie",
            value: state.serie,
            error: errorState.serie.error,
            success: state.serie && !errorState.serie.error ? true : undefined,
            helperText: errorState.serie.helperText,
            inputRef: serieRef,
            title: "Número de serie *",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca el número de serie",
            onChange: ({ target: { value } }) => handleChange(value, "serie"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "capacidad",
            value: state.capacidad,
            error: errorState.capacidad.error,
            success: state.capacidad && !errorState.capacidad.error ? true : undefined,
            helperText: errorState.capacidad.helperText,
            inputRef: capacidadRef,
            title: "Capacidad *",
            textAlign: "right",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca la capacidad",
            onChange: ({ target: { value } }) => handleChange(value, "capacidad"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "valorAseg",
            value: state.valorAseg,
            error: errorState.valorAseg.error,
            success: state.valorAseg && !errorState.valorAseg.error ? true : undefined,
            helperText: errorState.valorAseg.helperText,
            inputRef: valorAsegRef,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            title: "Valor Factura *",
            textAlign: "right",
            grid: { md: 4, lg: 4 },
            placeholder: "Introduzca el valor",
            onChange: ({ target: { value } }) => { (!isNaN(Number(value)) && value !=='' ) && 
            // handleChange(value, "valorAseg"),
            setState({...state,
                valorAseg: value, montoAsegurado: redondeo( parseFloat(toFixed((parseFloat(value)/parseFloat(state.paridad) ),2) ),2 )}) 
        }
            ,
            onKeyDown: () => { return; }
        },
        {
            disabled: true,
            id: "divisaID",
            value: state.divisaID,
            error: errorState.divisaID.error,
            success: Array.isArray(state.divisaID) && state.divisaID.length > 0 && state.divisaID && !errorState.divisaID.error ? true : undefined,
            helperText: errorState.divisaID.helperText,
            inputRef: divisaIDRef,
            title: "Divisa",
            placeholder: "Seleccionar la divisa",
            grid: { md: 2, lg: 2 },
            tipo: "select",
            data: listDivisas,
            onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
        },
        {
            disabled: state.divisaID === 69,
            id: "paridad",
            value: state.paridad,
            error: errorState.paridad.error,
            success: state.paridad && !errorState.paridad.error ? true : undefined,
            helperText: errorState.paridad.helperText,
            title: "Paridad Actual ",
            textAlign: "right",
            placeholder: "Paridad Actual",
            grid: { md: 2, lg: 2 },
            onChange: ({ target: { value } }) => { 
                (!isNaN(Number(value)) && value !=='') && 
                setState({...state,
                    paridad: value, montoAsegurado: redondeo( parseFloat(toFixed((parseFloat(state.valorAseg)/parseFloat(value) ),2) ),2 )}) 
             }
        },
        {
            disabled,
            id: "montoAsegurado",
            value: state.montoAsegurado,
            error: errorState.montoAsegurado.error,
            success: state.montoAsegurado && !errorState.montoAsegurado.error ? true :
                undefined,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            helperText: errorState.montoAsegurado.helperText,
            inputRef: montoAseguradoRef,
            title: "Valor Asegurado *",
            textAlign: "right",
            placeholder: "Valor Asegurado",
            grid: { md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { (rgxDecimal14.test(value)) && handleChange(value, "montoAsegurado") },
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "sustituye",
            value: state.sustituye,
            error: errorState.sustituye.error,
            success: state.sustituye && !errorState.sustituye.error ? true : undefined,
            helperText: errorState.sustituye.helperText,
            inputRef: sustituyeRef,
            title: "Sustituye A",
            grid: { md: 5, lg: 5 },
            placeholder: "Introduzca el nombre del equipo al que se sustituye",
            onChange: ({ target: { value } }) => handleChange(value, "sustituye"),
            onKeyDown: () => { return; }
        },
        {
            disabled: disabledActivo === true ? true : false,
            id: "sustituyeID",
            value: state.sustituyeID,
            error: errorState.sustituyeID.error,
            success: state.sustituyeID && !errorState.sustituyeID.error ? true : undefined,
            helperText: errorState.sustituyeID.helperText,
            inputRef: sustituyeIDRef,
            title: nSolicitud === "Baja de Seguro" ? "Activo a dar de Baja *" : "Activo a Sustituir *",
            placeholder: "Seleccione el Activo a Sustituir",
            tipo: "autocomplete",
            data: seleccionables.Activos,
            grid: { md: 5, lg: 5 },
            onChange: ({ value, label }) => { handleChangeSustituyeID(value, nSolicitud) }
        },
        {
            disabled: disabledActivo === true ? true : false,
            id: "activo",
            value: state.activo,
            error: errorState.activo.error,
            success: Array.isArray(state.activo) && state.activo.length > 0 && state.activo && !errorState.activo.error ? true : undefined,
            helperText: errorState.activo.helperText,
            title: "Estado",
            placeholder: "Seleccionar el estado",
            grid: { md: 2, lg: 2 },
            tipo: "select",
            data: [{
                label: 'ACTIVO',
                value: 'ACTIVO'
            },
            {
                label: 'INACTIVO',
                value: 'INACTIVO'
            }],
            onChange: ({ target: { value } }) => handleChange(value, "activo"),
        }, 
        {
            disabled: disabledActivo === true ? true : false,
            id: "motivo",
            value: state.motivo,
            error: errorState.motivo.error,
            success: state.motivo && !errorState.motivo.error ? true : undefined,
            helperText: errorState.motivo.helperText,
            inputRef: motivoRef,
            title: "Motivo*",
            grid: { md: 12, lg: 12 },
            placeholder: nSolicitud === "Baja de Seguro" ? "Introduza el motivo por el cual se dara de Baja" : "Introduza el motivo por el cual se sustituye",
            onChange: ({ target: { value } }) => handleChange(value, "motivo"),
            onKeyDown: () => { return; }
        },/* 
        {
            disabled,
            id: "omitir",
            value: state.omitir,
            checked: state.omitir,
            title: "Omitir monto en pólizas",
            tipo: "switch",
            grid: { md: 3, lg: 3 },
            onChange: (e) => {
                setState({ ...state, omitir: (e.target.checked ? 1 : 0) })
            }
        } */
    ];

 
    function InputsDisabled() {
        if (nSolicitud === "Baja de Seguro"){
                return true;
        }else{
            if(disabledActivo === true){
                return true;
            }
                return false;
        }
    }
    
    async function handleChangeDivisa (value, key) {
        const valorAseg = parseFloat(state.valorAseg);
        if (!isNaN(valorAseg)) {
            if (URLPolizasSeguroID && (parseInt(URLPolizasSeguroID) > 0)) {
                let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
                if (datosPolizaSeguro?.paridad) {
                    setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad, montoAsegurado: redondeo( parseFloat( toFixed( (valorAseg/parseFloat(datosPolizaSeguro.paridad)),2 ) ),2 )});
                }
            } else {
                let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
                if (historialParidades?.length > 0) {
                    setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad, montoAsegurado: redondeo( parseFloat( toFixed( (valorAseg/parseFloat(historialParidades[0].paridad)),2 ) ),2 )});
                }
            }
        }
    }

    let accionesFormulario = [
        {
            accionID: state.equipoOperID ? [3] : [2],
            loader,
            disabled,
            icono: SaveIcon,
            color: info,
            descripcion: "Guardar",
            onClick: () => handleGuardar({
                setLoader,
                setDisabled,
                setActualizar,
                actualizar,
                setNotificaciones,
                handleClose,
                handleFocus,
                refFocus: equipoRef,
                mensajeCorrecto: `El equipo se ${state.equipoOperID ? "actualizo" : "agrego"} correctamente`,
                asyncCallback: ftGuardar
            })
        },
        {
            disabled,
            icono: Close,
            color: danger,
            descripcion: "Cancelar",
            onClick: handleClose
        }

    ];

    //FUNCION PARA MANEJAR LOS ERRORES AL INTRODUCIR TEXTO
    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    //FUNCION PARA REALIZAR LAS VALIDACIONES
    async function handleValidaciones({ 
        activoAsegID, 
        ubicacionID, 
        equipo, 
        noEco, 
        modelo, 
        marca, 
        anio, 
        serie, 
        capacidad, 
        valorAseg, 
        motivo 
    }) {

        let error;
        let validaciones = [
            {
                condicion: !activoAsegID,
                ref: activoAsegIDRef,
                keyError: "activoAsegID",
                mensajeError: "Debe especificar el activo a asegurar"
            },
            /* 
            {
                condicion: !ubicacionID,
                ref: ubicacionIDRef,
                keyError: "ubicacionID",
                mensajeError: "Debe especificar la ubicación"
            },
            */ 
            {
                condicion: !equipo,
                ref: equipoRef, 
                keyError: "equipo",
                mensajeError: "Debe especificar el equipo"
            }, 
            {
                condicion: !noEco,
                ref: noEcoRef, 
                keyError: "noEco",
                mensajeError: "Debe especificar el número económico"
            }, 
            {
                condicion: !modelo,
                ref: modeloRef, 
                keyError: "modelo",
                mensajeError: "Debe especificar el modelo"
            }, 
            {
                condicion: !marca,
                ref: marcaRef, 
                keyError: "marca",
                mensajeError: "Debe especificar la marca"
            }, 
            {
                condicion: !anio,
                ref: anioRef, 
                keyError: "anio",
                mensajeError: "Debe especificar el año"
            }, 
            {
                condicion: !serie,
                ref: serieRef, 
                keyError: "serie",
                mensajeError: "Debe especificar el número de serie"
            }, 
            {
                condicion: !capacidad,
                ref: capacidadRef, 
                keyError: "capacidad",
                mensajeError: "Debe especificar la capacidad"
            }, 
            {
                condicion: !valorAseg,
                ref: valorAsegRef, 
                keyError: "valorAseg",
                mensajeError: "Debe especificar el valor asegurado"
            }
        ];
        
        if(nSolicitud === "Baja de Seguro"){

            validaciones.push(
                {
                    condicion: !motivo || trim(motivo) === "",
                    ref: motivoRef, keyError: "motivo",
                    mensajeError: "Debe especificar el motivo"
                }
            );
        }

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;
    }

    function ftGuardar() {
        async function ftGuardar() {
            let { equipoOperID,
                solSegFianID,
                activoAsegID,
                equipo,
                noEco,
                marca,
                modelo,
                anio,
                serie,
                capacidad,
                empresaID,
                ubicacionID,
                valorAseg,
                sustituye,
                motivo,
                sustituyeID,
                omitir,
                divisaID,
                paridad,
                activo,
                modeloCorrecto,
                montoAsegurado
            } = state;
            let error = await handleValidaciones({ 
                activoAsegID,
                ubicacionID, 
                equipo, 
                noEco, 
                modelo, 
                marca, 
                anio, 
                serie, 
                capacidad, 
                valorAseg, 
                motivo  });

            if (error) return error;


            if(nSolicitud === "Baja de Seguro"){
                //equipoOperID = equipoOperID === "" ? 0 : parseInt(equipoOperID) 
                solSegFianID = solSegFianID === "" ? 0 : parseInt(solSegFianID) 
                activoAsegID = (activoAsegID === "" || activoAsegID === null) ? 0 : parseInt(activoAsegID)
                equipo = equipo === "" ? "-" : equipo
                noEco = noEco === "" ? "-" : noEco
                marca = marca === "" ? "-" : marca
                modelo = modelo === "" ? "-" : modelo 
                anio = anio === "" ? 0 : parseInt(anio) 
                serie = serie === "" ? "-" : serie 
                capacidad = capacidad === "" ? "-" : capacidad 
                empresaID = (empresaID === "" || empresaID === null) ? 0 : parseInt(empresaID) 
                ubicacionID = (ubicacionID === "" || ubicacionID === null) ? 0 : parseInt(ubicacionID) 
                valorAseg = valorAseg === "" ? 0 : parseFloat(valorAseg)
                sustituye = sustituye === "" ? "-" : sustituye
                motivo = motivo === "" ? "-" : motivo 
                sustituyeID = (sustituyeID === "" || sustituyeID === null) ? 0 : parseInt(sustituyeID) 
                omitir = omitir === "" ? 0 : parseInt(omitir)  
                divisaID = divisaID === "" ? 0 : parseInt(divisaID) 
                paridad = paridad === "" ? 0 : parseFloat(paridad) 
                activo = activo === "" ? "-" : activo
                modeloCorrecto = modeloCorrecto === "" ? "-" : modeloCorrecto
                montoAsegurado= parseFloat(montoAsegurado)
            }

            if (equipoOperID) await updateSolSegFianEquipoOper({
                input: {
                    equipoOperID,
                    solSegFianID,
                    activoAsegID,
                    equipo,
                    noEco,
                    marca,
                    modelo,
                    anio: parseInt(anio, 10),
                    serie,
                    capacidad,
                    empresaID,
                    ubicacionID,
                    valorAseg: parseFloat(valorAseg),
                    sustituye: sustituye ? trim(sustituye) : "",
                    motivo,
                    sustituyeID: parseInt(sustituyeID),
                    omitir: parseInt(omitir),
                    divisaID,
                    paridad: paridad === undefined ? null : (paridad === "" ? null : paridad),
                    activo: trim(activo),
                    modeloCorrecto: trim(modelo),
                    montoAsegurado: parseFloat(montoAsegurado)
                }
            }, token)

            else await addSolSegFianEquipoOper({
                input: {
                    solSegFianID,
                    activoAsegID,
                    equipo: trim(equipo),
                    noEco: trim(noEco),
                    marca: trim(marca),
                    modelo: trim(modelo),
                    anio: parseInt(anio, 10),
                    serie: trim(serie),
                    capacidad: trim(capacidad),
                    empresaID,
                    ubicacionID,
                    valorAseg: parseFloat(valorAseg),
                    sustituye: sustituye ? trim(sustituye) : "",
                    motivo: trim(motivo),
                    sustituyeID: parseInt(sustituyeID),
                    omitir: parseInt(omitir),
                    divisaID,
                    paridad: paridad === undefined ? null : (paridad === "" ? null : paridad),
                    activo: trim(activo),
                    modeloCorrecto: trim(modelo),
                    montoAsegurado: parseFloat(montoAsegurado)
                }
            }, token);
        }
        return ftGuardar();
    }

    //FUNCION QUE PERMITE MANEJAR EL CERRADO DEL FORMULARIO MODAL
    //AL FINALIZAR EL REGISTRO DE DATOS
    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        if (!mostrarTabla) {
            if (onCerrarModal != undefined) { onCerrarModal(); }

        } else {
            if (onCerrarModal != undefined) { onCerrarModal(); }
            if (onOcultarTabla != undefined) { onOcultarTabla(); }

        }
    }

    async function handleChangeSustituyeID (value, nSolicitud) {
                   
        setDisabledActivo(true);
        setDisabled(true);
         setState({ ...state, ["sustituyeID"]: value});
         let res = await getInfoActAsegPolizas({ infoActAsegID: value}, token) 


         if (nSolicitud === "Baja de Seguro"){
            setState({ ...state, 
           ["sustituyeID"]: value === undefined ? "" : value, 
           ["activo"]: res.estado === undefined ? "" : res.estado,
           ["equipo"]: res.equipo === undefined ? "" : res.equipo, 
           ["noEco"]: res.noEco === undefined ? "" : res.noEco, 
           ["modeloCorrecto"]: res.modeloCorrecto === undefined ? "" : res.modeloCorrecto, 
           ["marca"]: res.marca === undefined ? "" : res.marca, 
           ["modelo"]: res.modelo === undefined ? "" : res.modelo, 
           ["anio"]: res.anio === undefined ? "" : res.anio, 
           ["serie"]: res.serie === undefined ? "" : res.serie,
           ["capacidad"]: res.capacidad === undefined ? "" : res.capacidad,
           ["valorAseg"]: res.valorFactura === undefined ? "" : res.valorFactura,
           ["divisaID"]: res.divisaID === undefined ? "" : res.divisaID, 
           ["paridad"]: res.paridad === undefined ? "" : res.paridad,
           ["sustituye"]: res.sustituye === undefined ? "" : res.sustituye,
           ["motivo"]: res.motivo === undefined ? "" : res.motivo });
         }else{
            setState({ ...state, ["sustituyeID"]: value, ["activo"]: res.estado });
        }
         setDisabledActivo(false);
         setDisabled(false);
}


    async function divisas(){
        if (URLPolizasSeguroID && Number(URLPolizasSeguroID) !== 0) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
         } else {
             let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: state.divisaID } }, token);        
             setState({ ...state, ["paridad"]: historialParidades[0].paridad });
         }
    }


    //FUNCION PARA MANEJAR LOS CAMBIOS
    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    useEffect(() => {
        async function ftEffect() {
            try {
                let ActivoAseg = await seleccionActivosAsegurado({ filtro: { clasificacion: "Equipo de Operaciones" } }, token);
                let Empresa = await seleccionEmpresa({ filtro: { empresaID: nEmpresaID } }, token);
                let Ubicaciones = await seleccionUbicaciones({ filtro: { empresaID: nEmpresaID } }, token);

                let Activos;
                if (pSeleccionTipoPoliza) {
                    if (pSeleccionTipoPoliza == 2) {
                        if (parseInt(URLSubPolizaID) > 0) {
                            Activos = await seleccionActivosAseguradosSolPol({ filtro: {subPolizaID: URLSubPolizaID == 0 ? null : parseInt(URLSubPolizaID)} }, token, nSolicitud, nRamoSeguro);
                        } else {
                            Activos = await seleccionActivosAseguradosSolPol({ filtro: {empresaID: nEmpresaID !== "" ? parseInt(nEmpresaID) : -1, ramoSeguroID: nSubRamoID !== "" ? parseInt(nSubRamoID) : -1, } }, token, nSolicitud, nRamoSeguro);
                        }
                    }
                    else {
                        if (parseInt(URLPolizasSeguroID) > 0) {
                            Activos = await seleccionActivosAseguradosSolPol({ filtro: {polizasSeguroID: URLPolizasSeguroID == 0 ? null : parseInt(URLPolizasSeguroID)} }, token, nSolicitud, nRamoSeguro);
                        } else {
                            Activos = await seleccionActivosAseguradosSolPol({ filtro: {empresaID: nEmpresaID !== "" ? parseInt(nEmpresaID) : -1, ramoSeguroID: nSubRamoID !== "" ? parseInt(nSubRamoID) : -1, } }, token, nSolicitud, nRamoSeguro);
                        }
                    }
                } else {
                    Activos = await seleccionActivosAseguradosSolPol({ 
                            filtro: {
                                empresaID: nEmpresaID == "" || nEmpresaID == 0 || nEmpresaID == null ? null : parseInt(nEmpresaID),
                                ramoSeguroID: Number(nRamoSeguroID)
                            }
                        }, 
                        token, 
                        nSolicitud, 
                        "Equipo de Contratistas y Maq. Pesada" 
                    );
                }

                setSeleccionables({ ActivoAseg, Empresa, Ubicaciones, Activos });
            }
            catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar, URLPolizasSeguroID]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    //INFORMACION A MOSTRAR
    return (<>
        {mostrarTabla && <Tabla
            infoTabla={infoTabla}
            asyncData={tablaSolSegFianEquipoOper}
            token={token}
            parametrosFijos={{ solSegFianID: URLSolSegFianID }}
        />}
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotificaciones={() => setNotificaciones({
                ...cleanNotificaciones
            })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={equipoRef}
        />
        {
            openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default SolSegFianEquipoOper;

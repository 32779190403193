import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { getContenido, tablaContenidos } from '../../../querys/SolSegFianContenidos';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionUbicaciones } from '../../../querys/Ubicaciones';
import { addContenido, updateContenido,deleteContenido } from '../../../mutations/SolSegFianContenidos';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import { rgxLetras, rgxNumeros,rgxNumeroDecimal4 } from '../../../helpers/regexp';
import { getEdad } from 'helpers/rutinas/metodos/rutinasGenerales';
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';
import moment from 'moment';
import { toFixed,redondeo } from '../../../helpers/index';
import { seleccionDepartamento } from '../../../querys/Departamento'
import { seleccionActivos } from '../../../querys/ActivosSolicitudes';
import { getPolizasSeguro } from '../../../querys/PolizasSeguro';

const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;

function SolSegFianContenidos({ datosDetalle,setOpenSolSegFianContenidos }) {
    let now = moment();
    let cTitulo2 = "ANEXOS: Acta de Matrimonio. Acta de Nacimiento de cada persona que se dara de alta";

    let URLPolizasSeguroID = null;

    const { token } = useSelector(state => state.login);
    const formato = "YYYY-MM-DD";
    const cleanState = {
        contenidoID:null,
        solsegfianID:datosDetalle.pSolSegFianID,
        empresaID:datosDetalle.pEmpresaID,
        empresa:"",
        activoID: "",
        activo:"",
        descripcion:"",
        dimensiones:"",
        modelo:"",
        marca:"",
        nSerie:"",
        tonelaje:"",
        voltaje:"",
        capacidad:"",
        unidadCapacidad: "",
        anio:"",
        departamentoID:datosDetalle.departamentoID,
        departamento:"",
        ubicacionID:"",
        ubicacion:"",
        costoUnitario:"",
        divisaID: datosDetalle.codigoMoneda == 'USD' ? 100 : 69,
        paridad: "1",
        costoAsegurado: ""
    };

    const cleanErrorState = {
        contenidoID: { error: false, helperText: "" },
        solsegfianID: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        activoID: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        dimensiones: { error: false, helperText: "" },
        modelo: { error: false, helperText: "" },
        marca: { error: false, helperText: "" },
        nSerie: { error: false, helperText: "" },
        tonelaje: { error: false, helperText: "" },
        voltaje: { error: false, helperText: "" },
        capacidad: { error: false, helperText: "" },
        unidadCapacidad: { error: false, helperText: "" },
        anio: { error: false, helperText: "" },
        departamentoID: { error: false, helperText: "" },
        ubicacionID: { error: false, helperText: "" },
        costoUnitario: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        costoAsegurado: { error: false, helperText: "" }
    }

    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [predeterminados, setPredeterminados] = useState({});

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    let titleAccion = state.contenidoID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Contenidos`;

    const contenidoIDRef = useRef(null);
    const solsegfianIDRef = useRef(null);
    const empresaIDRef = useRef(null);
    const activoIDRef = useRef(null);
    const descripcionRef = useRef(null);
    const dimensionesRef = useRef(null);
    const modeloRef = useRef(null);
    const marcaRef = useRef(null);
    const nSerieRef = useRef(null);
    const tonelajeRef = useRef(null);
    const voltajeRef = useRef(null);
    const capacidadRef = useRef(null);
    const unidadCapacidadRef = useRef(null);
    const anioRef = useRef(null);
    const departamentoIDRef = useRef(null);
    const ubicacionIDRef = useRef(null);
    const costoUnitarioRef = useRef(null);
    const divisaIDRef = useRef(null);
    const paridadRef = useRef(null);
    const costoAseguradoRef = useRef(null);

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "contenidoID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getContenido,
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "contenidoID" }],
        disabled: { multiseleccion: true },
        onClick: ({ contenidoID }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el registro`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { contenidoID }, asyncDelete: deleteContenido
            })
        })
    },
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "contenidoID" }],
        disabled: { multiseleccion: true },
        onClick: ({ contenidoID }) => {
            setDatosDetalleAdjuntos({
                pURLDoctoOrigenID: contenidoID,
                pTipo: datosDetalle.pfolioFormateado,
                pClasificacion: "",
                pTabla: "Solicitud de Contenidos",
                pTitulo2: cTitulo2,
                plUtilizaProps: true
            });
            setOpenDocumentacion(true);
        }
    }];

    let botones = [
        {
        icono: BackspaceOutlined,
        color: danger,
        descripcion: "Regresar",
        disabled: { multiseleccion: true },
        onClick: () => {
            setOpenSolSegFianContenidos(false);
        }
    },  
    {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];


    if (datosDetalle.pEstatus != "RECHAZADA"){
        botones = [...botones, {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {setOpen(true); divisas()},
            disabled: { multiseleccion: true }
        }]
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "contenidoID",
        color: primary,
        title: "Contenidos",
        title2: "ANEXO: Recuerde adjuntar documentos fuente de donde se deriva la obligacion, Factura, OC, fotografias.",
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "contenidoID", descripcion: "contenidoID", hide: true },
            { variable: "descripcion", descripcion: "Descripción" },
            { variable: "dimensiones", descripcion: "Dimensiones" },
            { variable: "modelo", descripcion: "Modelo" },
            { variable: "marca", descripcion: "Marca" },
            { variable: "tonelaje", descripcion: "Tonelaje" },
            { variable: "voltaje", descripcion: "Voltaje" },
            { variable: "capacidad", descripcion: "Capacidad" },
            { variable: "unidadCapacidad", descripcion: "Unidad" },
            { variable: "anio", descripcion: "Año" },
            { variable: "departamento", descripcion: "Departamento" },
            { variable: "ubicacion", descripcion: "Ubicación" },
            { variable: "costoUnitario", descripcion: "Costo Unitario" },
            { variable: "costoAsegurado", descripcion: "Costo Asegurado" },
        ],
        formato: [
            {
                columnas: ["costoUnitario","costoAsegurado"], tipo: "moneda"
            }
        ],
        responsiveTitle: ["descripcion","departamento"],
        filter: [
            { campo: "departamento", placeholder: "Departamento" },
            { campo: "area", placeholder: "Área" },
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }

    const inputs = [
        {
            disabled:true,
            id: "empresaID",
            value: state.empresaID,
            error: errorState.empresaID.error,
            success: state.empresaID && !errorState.empresaID.error ? true : undefined,
            helperText: errorState.empresaID.helperText,
            inputRef: empresaIDRef,
            title: "Empresa *",
            placeholder: "Seleccionar Empresa",
            tipo: "autocomplete",
            data: seleccionables.empresas,
            grid: { md: 3, lg: 3 },
            onChange: (valor) => { setState({ ...state, empresaID: valor ? valor.value : '',  }) }
        },
        {
            disabled,
            id: "activoID",
            value: state.activoID,
            error: errorState.activoID.error,
            success: state.activoID && !errorState.activoID.error ? true : undefined,
            helperText: errorState.activoID.helperText,
            inputRef: activoIDRef,
            title: "Activo *",
            placeholder: "Seleccionar Activo",
            tipo: "autocomplete",
            data: seleccionables.activos,
            grid: { md: 4, lg: 4 },
            onChange: (valor) => { setState({ ...state, activoID: valor ? valor.value : '',  }) }
        },
        {
            disabled,
            id: "descripcion",
            value: state.descripcion,
            error: errorState.descripcion.error,
            success: state.descripcion && !errorState.descripcion.error ? true : undefined,
            helperText: errorState.descripcion.helperText,
            inputRef: descripcionRef,
            title: "Descripcion",
            placeholder: "Capture la descripcion",
            grid: { sm: 12, md: 5, lg: 5 },
            onChange: ({ target: { value } }) => { handleChange(value, "descripcion") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, descripcion: { error: state.descripcion.length > 100 && true, helperText: state.descripcion.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "dimensiones",
            value: state.dimensiones,
            error: errorState.dimensiones.error,
            success: state.dimensiones && !errorState.dimensiones.error ? true : undefined,
            helperText: errorState.dimensiones.helperText,
            inputRef: dimensionesRef,
            title: "Dimensiones",
            placeholder: "Capture la dimensiones",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { handleChange(value, "dimensiones") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, dimensiones: { error: state.dimensiones.length > 100 && true, helperText: state.dimensiones.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "modelo",
            value: state.modelo,
            error: errorState.modelo.error,
            success: state.modelo && !errorState.modelo.error ? true : undefined,
            helperText: errorState.modelo.helperText,
            inputRef: modeloRef,
            title: "Modelo *",
            placeholder: "Capture la modelo",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { handleChange(value, "modelo") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, modelo: { error: state.modelo.length > 100 && true, helperText: state.modelo.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "marca",
            value: state.marca,
            error: errorState.marca.error,
            success: state.marca && !errorState.marca.error ? true : undefined,
            helperText: errorState.marca.helperText,
            inputRef: marcaRef,
            title: "Marca *",
            placeholder: "Capture la marca",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { handleChange(value, "marca") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, marca: { error: state.marca.length > 100 && true, helperText: state.marca.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "nSerie",
            value: state.nSerie,
            error: errorState.nSerie.error,
            success: state.nSerie && !errorState.nSerie.error ? true : undefined,
            helperText: errorState.nSerie.helperText,
            inputRef: nSerieRef,
            title: "N° Serie *",
            placeholder: "Capture la Serie",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { handleChange(value, "nSerie") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, nSerie: { error: state.nSerie.length > 100 && true, helperText: state.nSerie.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "tonelaje",
            value: state.tonelaje,
            error: errorState.tonelaje.error,
            success: state.tonelaje && !errorState.tonelaje.error ? true : undefined,
            helperText: errorState.tonelaje.helperText,
            inputRef: tonelajeRef,
            title: "Tonelaje",
            placeholder: "Capture el tonelaje",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { handleChange(value, "tonelaje") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, tonelaje: { error: state.tonelaje.length > 100 && true, helperText: state.tonelaje.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "voltaje",
            value: state.voltaje,
            error: errorState.voltaje.error,
            success: state.voltaje && !errorState.voltaje.error ? true : undefined,
            helperText: errorState.voltaje.helperText,
            inputRef: voltajeRef,
            title: "Voltaje",
            placeholder: "Capture el Voltaje",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { handleChange(value, "voltaje") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, voltaje: { error: state.voltaje.length > 100 && true, helperText: state.voltaje.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "capacidad",
            value: state.capacidad,
            error: errorState.capacidad.error,
            success: state.capacidad && !errorState.capacidad.error ? true : undefined,
            helperText: errorState.capacidad.helperText,
            inputRef: capacidadRef,
            title: "Capacidad",
            placeholder: "Capture la Capacidad",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChange(value, "capacidad") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, capacidad: { error: state.capacidad.length > 100 && true, helperText: state.capacidad.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "unidadCapacidad",
            value: state.unidadCapacidad,
            error: errorState.unidadCapacidad.error,
            success: state.unidadCapacidad && !errorState.unidadCapacidad.error ? true : undefined,
            helperText: errorState.unidadCapacidad.helperText,
            inputRef: unidadCapacidadRef,
            title: "Unidad *",
            placeholder: "Capture la Unidad",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { handleChange(value, "unidadCapacidad") },
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "anio",
            value: state.anio,
            error: errorState.anio.error,
            success: state.anio && !errorState.anio.error ? true : undefined,
            helperText: errorState.anio.helperText,
            inputRef: anioRef,
            title: "Año",
            placeholder: "Capture el año",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChange(value, "anio") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, anio: { error: state.anio.length > 100 && true, helperText: state.anio.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: true,
            id: "departamentoID",
            value: state.departamentoID,
            error: errorState.departamentoID.error,
            success: state.departamentoID && !errorState.departamentoID.error ? true : undefined,
            helperText: errorState.departamentoID.helperText,
            inputRef: departamentoIDRef,
            title: "Departamento *",
            placeholder: "Seleccionar Departamento",
            tipo: "autocomplete",
            data: seleccionables.departamentos,
            grid: { md: 4, lg: 4 },
            onChange: (valor) => { setState({ ...state, departamentoID: valor ? valor.value : '',  }) }
        },
        {
            disabled,
            id: "ubicacionID",
            value: state.ubicacionID,
            error: errorState.ubicacionID.error,
            success: state.ubicacionID && !errorState.ubicacionID.error ? true : undefined,
            helperText: errorState.ubicacionID.helperText,
            inputRef: ubicacionIDRef,
            title: "Ubicación *",
            placeholder: "Seleccionar Ubicación",
            tipo: "autocomplete",
            data: seleccionables.ubicaciones,
            grid: { md: 5, lg: 5 },
            onChange: (valor) => { setState({ ...state, ubicacionID: valor ? valor.value : '',  }) }
        },
        {
            disabled,
            id: "costoUnitario",
            value: state.costoUnitario,
            error: errorState.costoUnitario.error,
            success: state.costoUnitario && !errorState.costoUnitario.error ? true : undefined,
            helperText: errorState.costoUnitario.helperText,
            inputRef: costoUnitarioRef,
            title: "Costo Unitario *",
            icono: <span>{'$'}</span>,//<IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            placeholder: "Capture el costo",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { !isNaN(Number(value)) && 
                // handleChange(value, "costoUnitario") 
                setState({
                    ...state,
                    costoUnitario: value, costoAsegurado: redondeo(parseFloat(toFixed(parseFloat((value) / parseFloat(state.paridad)), 2)), 2)
                })
            },
            onKeyDown: () => { return; }
        },
        {
            disabled: true,
            id: "divisaID",
            value: state.divisaID,
            error: errorState.divisaID.error,
            success: Array.isArray(state.divisaID) && state.divisaID.length > 0 && state.divisaID && !errorState.divisaID.error ? true : undefined,
            helperText: errorState.divisaID.helperText,
            inputRef: divisaIDRef,
            title: "Divisa",
            placeholder: "Seleccionar la divisa",
            grid: { md: 2, lg: 2 },
            tipo: "select",
            data: listDivisas,
            onChange: ({ target: { value } }) => 
            handleChangeDivisa(value, "divisaID"),
        },
        {
            disabled: state.divisaID == 69,
            id: "paridad",
            value: state.paridad,
            error: errorState.paridad.error,
            success: state.paridad && !errorState.paridad.error ? true : undefined,
            helperText: errorState.paridad.helperText,
            title: "Paridad Actual",
            textAlign: "right",
            placeholder: "Paridad Actual",
            grid: { md: 2, lg: 2 },
            onChange: ({ target: { value } }) => { (!isNaN(Number(value)) && value !=='') &&
                setState({
                    ...state,
                    paridad: value, costoAsegurado: redondeo(parseFloat(toFixed(parseFloat((state.costoUnitario) / parseFloat(value)), 2)), 2)
                })
            }
        },
        {
            disabled,
            id: "costoAsegurado",
            value: state.costoAsegurado,
            error: errorState.costoAsegurado.error,
            success: state.costoAsegurado && !errorState.costoAsegurado.error ? true : undefined,
            helperText: errorState.costoAsegurado.helperText,
            icono: <span>{'$'}</span>,
            posicionIcono: "start",
            formatoImportes: true,
            title: "Costo Asegurado ",
            textAlign: "right",
            placeholder: "Costo Asegurado",
            grid: { md: 2, lg: 2 }
        },
    ];


    let accionesFormulario = [{
        accionID: state.gtosMedMayID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: empresaIDRef,
            mensajeCorrecto: `El registro se ${state.contenidoID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    async function handleValidaciones({ activoID,modelo,marca,nSerie,unidadCapacidad,ubicacionID, costoUnitario,paridad}) {

        let error;
        let validaciones = [
            {
                condicion: !activoID,
                keyError: "activoID",
                mensajeError: "Debe seleccionar el activo"
            },
            {
                condicion: !modelo || trim(modelo) === "",
                ref: modeloRef, keyError: "modelo",
                mensajeError: "Debe ingresar una descripción"
            },
            {
                condicion: !marca || trim(marca) === "",
                ref: marcaRef, keyError: "marca",
                mensajeError: "Debe ingresar una descripción"
            },
            {
                condicion: !nSerie || trim(nSerie) === "",
                ref: nSerieRef, keyError: "nSerie",
                mensajeError: "Debe especificar la serie"
            },
            {
                condicion: !unidadCapacidad || trim(unidadCapacidad) === "",
                ref: unidadCapacidadRef, keyError: "unidadCapacidad",
                mensajeError: "Debe especificar la serie"
            },
            {
                condicion: !ubicacionID,
                keyError: "ubicacionID",
                mensajeError: "Debe especificar la ubicación"
            },
            {
                condicion: !costoUnitario || costoUnitario === "",
                ref: costoUnitarioRef, keyError: "costoUnitario",
                mensajeError: "Debe especificar el costo"
            },
            {
                condicion: !paridad || paridad === "",
                ref: paridadRef, keyError: "paridad",
                mensajeError: "Debe especificar la paridad"
            },
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }
    
    async function handleChangeDivisa (value, key) {
        if (URLPolizasSeguroID && (parseInt(URLPolizasSeguroID) > 0)) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            if (datosPolizaSeguro?.paridad) {            
                setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
            }
        } else {
            let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
            if (historialParidades?.length > 0) {
                setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad });
            }
        }
    }
    
    async function divisas(){
        if (URLPolizasSeguroID && (parseInt(URLPolizasSeguroID) > 0)) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
         } else {
             let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: state.divisaID } }, token);        
             setState({ ...state, ["paridad"]: historialParidades[0].paridad });
         }
    }

    function ftGuardar() {
        async function ftGuardar() {

            let { contenidoID,solsegfianID,empresaID,activoID,descripcion,dimensiones,modelo,marca,nSerie,tonelaje,voltaje,capacidad,unidadCapacidad,anio,departamentoID,ubicacionID,costoUnitario,divisaID,paridad, costoAsegurado} = state;

            let error = await handleValidaciones({ activoID,modelo,marca,nSerie,unidadCapacidad,ubicacionID, costoUnitario,paridad});
            if (error) return error;

            if (contenidoID) await updateContenido({
                input: { contenidoID,solsegfianID,empresaID,activoID,descripcion,dimensiones,modelo,marca,nSerie,tonelaje,voltaje,capacidad,unidadCapacidad,anio,departamentoID,ubicacionID,costoUnitario:parseFloat(costoUnitario),divisaID,paridad: parseFloat(paridad), costoAsegurado: parseFloat(costoAsegurado) }
            }, token);
            else await addContenido({
                input: { contenidoID,solsegfianID,empresaID,activoID,descripcion,dimensiones,modelo,marca,nSerie,tonelaje,voltaje,capacidad,unidadCapacidad,anio,departamentoID,ubicacionID,costoUnitario: parseFloat(costoUnitario),divisaID,paridad: parseFloat(paridad), costoAsegurado: parseFloat(costoAsegurado) }
            }, token);
        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }



    useEffect(() => {
        async function ftEffect() {
            try {
                let empresas = await seleccionEmpresa({ filtro: {} }, token);
                let ubicaciones = await seleccionUbicaciones({ filtro: {empresaID: datosDetalle.pEmpresaID} }, token);
                let departamentos = await seleccionDepartamento({ filtro: {} }, token);
                let activos = await seleccionActivos({ filtro: {} }, token);

                setSeleccionables({ empresas,ubicaciones,departamentos,activos });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
            <Tabla
            infoTabla={infoTabla}
            asyncData={tablaContenidos}
            token={token}
            parametrosFijos={{ solsegfianID: datosDetalle.pSolSegFianID }}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={empresaIDRef}
        />
        {
            openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default SolSegFianContenidos;

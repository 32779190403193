import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

import { tipoSolicitudSeguro } from '../../../helpers/datosFijos/tipoSolicitudSeguro';
// Redux
import { useSelector } from "react-redux";
import { NetworkError } from "../../../_layout/genericComponents/Metodos/";

import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertGeneric, handleAlertMutationGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import { getSolOtrosSegurosyFianzas, getAllSolOtrosSegurosyFianzas, tablaSolOtrosSegurosyFianzas } from '../../../querys/SolOtrosSegurosyFianzas/';
import { addSolOtrosSegFianFianzas, updateSolOtrosSegFianFianzas, deleteSolOtrosSegFianFianzas } from '../../../mutations/SolOtrosSegurosyFianzas/';
import { seleccionEmpresa } from 'querys/Empresas';
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';
import { getAllHistorialParidades } from '../../../querys/HistorialParidades';
import { getPolizasSeguro } from '../../../querys/PolizasSeguro';

import moment from 'moment';
import {rgxNumeros, rgxDecimal14 } from '../../../helpers/regexp';
import {toFixed, redondeo } from '../../../helpers';

const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;
function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }
function OtrosSegurosyFianzas({datosDetalle,setOpenSolSegFianzas,lMostrarFormularioCompleto}) {
    
    let now = moment();
    let formato = "YYYY-MM-DD";
    let history = useHistory();
    let lUtilizaProps = true;
    let URLPolizasSeguroID = null;
    let URLSolSegFianID = null;
    let mostrarTabla = false;
    let nFianzaID = null;
    let nEmpresaID = null;
    let nUsuarioSolicita = null;
    let datosDetalleURL = {};
    let cfolioFormateado = null;
    let cEstatus = null;
    let nSolicitud = null;
    let cTitulo2 = "ANEXOS: Recuerde adjuntar documento fuente de donde se deriva la obligación.";

    let { params } = useParams();
    if (params.length === 0) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { pURLSolSegFianID, pEmpresaID, pUsuarioSolicita, datosDetalle, pfolioFormateado, pURLPolizasSeguroID, pSolicitud } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        URLSolSegFianID = pURLSolSegFianID;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        datosDetalleURL = datosDetalle;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        nSolicitud = pSolicitud;
    }
    let { onCerrarModal, onOcultarTabla } = datosDetalleURL;
    mostrarTabla = URLSolSegFianID ? true : false;

    if (datosDetalleURL != undefined && datosDetalleURL.length > 0) {
        lUtilizaProps = false;
    }

    if (lUtilizaProps) {
        let { pEstatus, pSolSegFianID, pFianzaID, pEmpresaID, pUsuarioSolicita, onCerrarModal, mostrarTabla, onOcultarTabla, pfolioFormateado, pURLPolizasSeguroID, pSolicitud } = datosDetalle;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        URLSolSegFianID = pSolSegFianID
        mostrarTabla = mostrarTabla;
        nFianzaID = pFianzaID;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        cEstatus = pEstatus;
        nSolicitud = pSolicitud;
    }
    mostrarTabla = lMostrarFormularioCompleto;
    const { token, usuario } = useSelector(state => state.login)
    const cleanState = {
        fianzaID: nFianzaID,
        solSegFianID: URLSolSegFianID,
        empresaID: nEmpresaID,
        monto: "",
        tipo: "",
        docFuente: "",
        intGarantizar: "",
        beneficiario: nUsuarioSolicita,
        divisaID: datosDetalle.codigoMoneda == 'USD' ? 100 : 69,
        paridad: "1",
        montoAsegurado: "0"
    };

    const cleanErrorState = {
        fianzaID: { error: false, helperText: "" },
        solSegFianID: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        monto: { error: false, helperText: "" },
        tipo: { error: false, helperText: "" },
        docFuente: { error: false, helperText: "" },
        intGarantizar: { error: false, helperText: "" },
        beneficiario: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        montoAsegurado: { error: false, helperText: "" }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [seleccionables, setSeleccionables] = useState({});
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [disabled, setDisabled] = useState(false);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(!mostrarTabla);
    const [alert, setAlert] = useState(null);
    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    
    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    let titleAccion = state.fianzaID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Otros Seguros/Fianzas`;

    let fianzaIDRef = useRef(null);
    let solSegFianIDRef = useRef(null);
    let empresaIDRef = useRef(null);
    let montoRef = useRef(null);
    let tipoRef = useRef(null);
    let docFuenteRef = useRef(null);
    let intGarantizarRef = useRef(null);
    let beneficiarioRef = useRef(null);
    const divisaIDRef = useRef(null);
    const montoAseguradoRef = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "fianzaID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getSolOtrosSegurosyFianzas,
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "fianzaID" },
        { campo: "tipo" }],
        disabled: { multiseleccion: true },
        onClick: ({ fianzaID, tipo }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el registro de ${tipo}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { fianzaID }, asyncDelete: deleteSolOtrosSegFianFianzas
            })
        })
    },
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "fianzaID" }],
        disabled: { multiseleccion: true },
        onClick: ({ fianzaID }) => {
            //setRedireccionarFormulario({ fianzaID });

            setDatosDetalleAdjuntos({
                pURLDoctoOrigenID:fianzaID ,
                 pTipo:cfolioFormateado ,
                 pClasificacion: "",
                 pTabla: "Solicitud Seguro Fianzas",
                 pTitulo2: cTitulo2,
                 plUtilizaProps: true
            });
            setOpenDocumentacion(true);
        }
    }];

    let botones = [{
        icono: BackspaceOutlined,
        color: danger,
        descripcion: "Regresar",
        disabled: { multiseleccion: true },
        onClick: () => {
           // history.push(`/gen/SolicitudesSeguros/`);
           setOpenSolSegFianzas();
        }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    
    if (cEstatus != "RECHAZADA"){
        botones = [...botones, {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {setOpen(true); divisas()},
            disabled: { multiseleccion: true }
        }]
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "fianzaID",
        color: primary,
        title: "Otros Seguros y Fianzas",
        title2: cTitulo2,
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "fianzaID", descripcion: "fianzaID", hide: true },
            { variable: "solSegFianID", descripcion: "solSegFianID", hide: true },
            { variable: "tipo", descripcion: "Tipo" },
            { variable: "monto", descripcion: "Monto" },
            { variable: "divisa", descripcion: "Divisa" },
            { variable: "paridad", descripcion: "Paridad" },
            { variable: "montoAsegurado", descripcion: "Monto Asegurado" },
            { variable: "beneficiario", descripcion: "Beneficiario" },
            { variable: "docFuente", descripcion: "Docto Fuente" },
            { variable: "intGarantizar", descripcion: "Interés Garantía" },
        ],
        responsiveTitle: ["tipo", "monto","montoAsegurado"],
        filter: [
            { campo: "tipo", placeholder: "Tipo" },
            { campo: "beneficiario", placeholder: "Beneficiario" },
        ],
        formato: [
            {
                columnas: ["monto","montoAsegurado"], tipo: "moneda"
            },
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }

    const inputs = [
        {
            disabled:true,
            id: "empresaID",
            value: state.empresaID,
            error: errorState.empresaID.error,
            success: state.empresaID && !errorState.empresaID.error ? true : undefined,
            helperText: errorState.empresaID.helperText,
            inputRef: empresaIDRef,
            title: "Empresa *",
            placeholder: "Seleccione empresa",
            grid: { md: 3, lg: 3 },
            tipo: "autocomplete",
            data: seleccionables.empresas,
            onChange: (value) => setState({ ...state, empresaID: value ? Number(value.value) : null }),
        },
        {
            disabled,
            id: "tipo",
            value: state.tipo,
            error: errorState.tipo.error,
            success: state.tipo && !errorState.tipo.error ? true :
                undefined,
            helperText: errorState.tipo.helperText,
            inputRef: tipoRef,
            title: "Tipo *",
            placeholder: "Especifique el tipo",
            grid: { md: 9, lg: 9 },
            onChange: ({ target: { value } }) => handleChange(value, "tipo"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "monto",
            value: state.monto,
            error: errorState.monto.error,
            success: state.monto && !errorState.monto.error ? true :
                undefined,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            helperText: errorState.monto.helperText,
            inputRef: montoRef,
            title: "Monto Factura *",
            textAlign: "right",
            placeholder: "Especifique el monto",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { (!isNaN(Number(value)) && value !=='' ) && setState({...state,
                 monto: value, montoAsegurado: redondeo( parseFloat(toFixed((parseFloat(value)/parseFloat(state.paridad) ),2) ),2 )}) 
                },
            onKeyDown: () => { return; }
        }, {
            disabled: true,
            id: "divisaID",
            value: state.divisaID,
            error: errorState.divisaID.error,
            success: Array.isArray(state.divisaID) && state.divisaID.length > 0 && state.divisaID && !errorState.divisaID.error ? true : undefined,
            helperText: errorState.divisaID.helperText,
            inputRef: divisaIDRef,
            title: "Divisa",
            placeholder: "Seleccionar la divisa",
            grid: { md: 3, lg: 3 },
            tipo: "select",
            data: listDivisas,
            onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
        },
        {
            disabled: state.divisaID === 69,
            id: "paridad",
            value: state.paridad,
            error: errorState.paridad.error,
            success: state.paridad && !errorState.paridad.error ? true : undefined,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            helperText: errorState.paridad.helperText,
            title: "Paridad",
            textAlign: "right",
            placeholder: "Paridad",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { (!isNaN(Number(value)) && value !=='' )  && setState({...state,
                paridad: value, montoAsegurado: redondeo( parseFloat(toFixed(parseFloat((state.monto)/parseFloat(value)),2 )),2 )}) 
            }
        },
        {
            disabled,
            id: "montoAsegurado",
            value: state.montoAsegurado,
            error: errorState.montoAsegurado.error,
            success: state.montoAsegurado && !errorState.montoAsegurado.error ? true :
                undefined,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            helperText: errorState.montoAsegurado.helperText,
            inputRef: montoAseguradoRef,
            title: "Monto Asegurado",
            textAlign: "right",
            placeholder: "Especifique el monto",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { (rgxDecimal14.test(value)) && handleChange(value, "montoAsegurado") },
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "beneficiario",
            value: state.beneficiario,
            error: errorState.beneficiario.error,
            success: state.beneficiario && !errorState.beneficiario.error ? true :
                undefined,
            helperText: errorState.beneficiario.helperText,
            inputRef: beneficiarioRef,
            title: "Beneficiario",
            placeholder: "Especifique el Beneficiario",
            grid: { md: 6, lg: 6 },
            onChange: ({ target: { value } }) => handleChange(value, "beneficiario"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "docFuente",
            value: state.docFuente,
            error: errorState.docFuente.error,
            success: state.docFuente && !errorState.docFuente.error ? true :
                undefined,
            helperText: errorState.docFuente.helperText,
            inputRef: docFuenteRef,
            title: "Documento Fuente *",
            placeholder: "Especifique el nombre del documento fuente",
            grid: { md: 6, lg: 6 },
            onChange: ({ target: { value } }) => handleChange(value, "docFuente"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "intGarantizar",
            value: state.intGarantizar,
            error: errorState.intGarantizar.error,
            success: state.intGarantizar && !errorState.intGarantizar.error ? true :
                undefined,
            helperText: errorState.intGarantizar.helperText,
            inputRef: intGarantizarRef,
            title: "Interes a Garantizar *",
            placeholder: "Especifique el dato de interes a garantizar",
            grid: { md: 12, lg: 12 },
            multiline:true,
            onChange: ({ target: { value } }) => handleChange(value, "intGarantizar"),
            onKeyDown: () => { return; }
        }
    ];




    async function handleChangeDivisa (value, key) {
        if (URLPolizasSeguroID && (parseInt(URLPolizasSeguroID) > 0)) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad, montoAsegurado: redondeo( parseFloat( toFixed( (parseFloat(state.monto)/parseFloat(datosPolizaSeguro.paridad)),2 ) ),2 )});
        } else {
            let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);            
            // redondeo( parseFloat(toFixed(sumAsegTotal,2)),2 )
            setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad, montoAsegurado: redondeo( parseFloat( toFixed( (parseFloat(state.monto)/parseFloat(historialParidades[0].paridad)),2 ) ),2 )});
        }
    }

    let accionesFormulario = [{
        accionID: state.fianzaID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: fianzaIDRef,
            mensajeCorrecto: `La solicitud de seguro ${state.fianzaID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        if (!mostrarTabla) {
            if (onCerrarModal != undefined) { onCerrarModal(); }
        } else {
            // if (onCerrarModal!= undefined) {onCerrarModal();}
            //  if (onOcultarTabla!= undefined) {onOcultarTabla();}
        }
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function setRedireccionarFormulario({ fianzaID }) {
        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${fianzaID}", "pTipo": "${cfolioFormateado}", "pTabla": "Solicitud Seguro Fianzas", "pTitulo2": "${cTitulo2}" }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                let empresas = await seleccionEmpresa({ filtro: { empresaID: nEmpresaID } }, token);
                if (empresas.length === 1) {
                    setState(state => (
                        {
                            ...state, empresaID: empresas[0].value
                        })
                    )
                }

                setSeleccionables({ empresas });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    async function handleValidaciones({ solSegFianID, tipo, monto, empresaID, docFuente, intGarantizar, divisaID }) {

        let error;

        let validaciones = [
            {
                condicion: !solSegFianID,
                ref: solSegFianIDRef, keyError: "solSegFianID",
                mensajeError: "El solSegFianID es dato obligatorio. Debe seleccionar una solSegFianID"
            },
            {
                condicion: !empresaID,
                ref: empresaIDRef, keyError: "empresaID",
                mensajeError: "La empresa es un dato obligatorio. Debe seleccionar una empresa"
            },
            {
                condicion: !tipo || trim(tipo) == "",
                ref: tipoRef, keyError: "tipo",
                mensajeError: "El tipo es dato obligatorio. Debe seleccionar un tipo"
            },
            {
                condicion: tipo && trim(tipo).length > 200,
                ref: tipoRef, keyError: "tipo",
                mensajeError: "El dato de tipo excede el tamaño"
            },
            {
                condicion: !monto,
                ref: montoRef, keyError: "monto",
                mensajeError: "El monto es dato obligatorio. Debe especificar un monto"
            },
            {
                condicion: !rgxNumeros.test(divisaID), keyError: "divisaID",
                mensajeError: "Debe seleccionar una divisa"
            },
            {
                condicion: !docFuente || trim(docFuente) === "",
                ref: docFuenteRef, keyError: "docFuente",
                mensajeError: "El nombre del documento fuente es dato obligatorio. Debe especificar el nombre del documento fuente"
            },
            {
                condicion: !intGarantizar || trim(intGarantizar) === "",
                ref: intGarantizarRef, keyError: "intGarantizar",
                mensajeError: "El dato garantizar obligatorio. Debe especificar dato de garantizar."
            }

        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }



    async function divisas(){
        if (URLPolizasSeguroID && (parseInt(URLPolizasSeguroID) > 0)) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
        } else {
            let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: state.divisaID } }, token);    
            setState({ ...state, ["paridad"]: historialParidades[0].paridad });
        }
    }


    function setRedireccionarFormulario({ fianzaID }) {

        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${fianzaID}", "pTipo": "${cfolioFormateado}", "pTabla": "Solicitud Póliza de Otros Seguros y Fianzas", "pTitulo2": "${cTitulo2}" }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    function ftGuardar() {
        async function ftGuardar() {
            let { fianzaID, solSegFianID,
                tipo, monto,
                empresaID, docFuente, intGarantizar, beneficiario, divisaID, paridad,montoAsegurado } = state;

            let error = await handleValidaciones({ solSegFianID, tipo, monto, empresaID, docFuente, intGarantizar, divisaID });
            if (error) return error;
            if (fianzaID) await addSolOtrosSegFianFianzas({
                input: {
                    fianzaID, solSegFianID, empresaID, monto: parseFloat(monto),
                    tipo: trim(tipo), docFuente: trim(docFuente),
                    intGarantizar: trim(intGarantizar), beneficiario: trim(beneficiario),divisaID, paridad: parseFloat(paridad), montoAsegurado: parseFloat(montoAsegurado)
                }
            }, token);
            else await updateSolOtrosSegFianFianzas({
                input: {
                    fianzaID: null, solSegFianID, empresaID, monto: parseFloat(monto),
                    tipo: trim(tipo), docFuente: trim(docFuente),
                    intGarantizar: trim(intGarantizar), beneficiario: trim(beneficiario),divisaID, paridad: parseFloat(paridad), montoAsegurado: parseFloat(montoAsegurado)
                }
            }, token);
        }
        return ftGuardar();
    }

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        {mostrarTabla && <Tabla
            infoTabla={infoTabla}
            asyncData={tablaSolOtrosSegurosyFianzas}
            token={token}
            parametrosFijos={{ solSegFianID: URLSolSegFianID }}
        />}
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={fianzaIDRef}
        />

        {
             openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos}   setOpenDocumentacion = { () => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default OtrosSegurosyFianzas;

import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

//Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from 'react-router-dom';

//master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
//componentes genericos
import SweetAlert from '_layout/genericComponents/ModalConfirmacion';

//Queries
import { getSolSegFianEquipoElec, getAllSolSegFianEquipoElec, seleccionSolSegFianEquipoElec, tablaSolSegFianEquipoElec } from '../../../querys/Solsegfian_equipoelec';



import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { seleccionActivosAsegurado } from '../../../querys/ActivosAsegurado';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionUbicaciones } from '../../../querys/Ubicaciones';
// import { seleccionActivosAseguradosSolPol } from '../../../querys/ActivosAseguradosSolPol';
import { getPolizasSeguro } from '../../../querys/PolizasSeguro';

import { seleccionActivosAsegurado as seleccionActivosAseguradosSolPol, getInfoActAsegPolizas } from '../PolizasSeguro/ConsultasActivos';
//Mutations
import { addSolSegFianEquipoElec, updateSolSegFianEquipoElec, deleteSolSegFianEquipoElec } from '../../../mutations/Solsegfian_equipoelec';

import { rgxdeInt11, rgxInputTexto30, rgxNumeros, rgxDecimal8, rgxDecimal14 } from '../../../helpers/regexp';
import { ftFormatoImportes, toFixed,redondeo } from '../../../helpers/index';
import * as Permisos from '../Permisos';

//iconos
import SyncIcon from '@material-ui/icons/Sync';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import moment from 'moment';
//HANDLERS
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../../_layout/helpers/handles';

//COLORES
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import chartist from 'chartist';
import Documentacion from '../Documentacion';

const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;
function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

function SolSegFianEquipoElec({ datosDetalle, setOpenSolSegEquipoElectrico, lMostrarFormularioCompleto }) {
    
    let now = moment();
    let formato = "YYYY-MM-DD";
    let history = useHistory();
    let lUtilizaProps = true;
    let URLSolSegFianID = null;
    let URLPolizasSeguroID = null;
    let URLSubPolizaID = null;
    let mostrarTabla = false;
    let nEquipoElecID = null;
    let nEmpresaID = null;
    let nUsuarioSolicita = null;
    let datosDetalleURL = {};
    let cfolioFormateado = null;
    let cEstatus = null;
    let nRamoSeguroID = null;
    let nSubRamoID = null;
    let nSolicitud = null;
    let pSeleccionTipoPoliza = null;
    let cTitulo2 = "ANEXOS: En caso de tener más equipos a asegurar favor de anexarlos en un formato en Excel. Alta: Factura de equipo y OC y gastos de flete. Especificaciones técnicas. Fotografía del equipo y serie. Indicar el equipo que sustituye y si se encuentra ya fuera de servicio. Baja: Indicar si procede destrucción o donación y su autorización, adjuntar factura, OC y LIBRO DE BAJA firmado.";

    let { params } = useParams();
    if (params.length === 0) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { pURLSolSegFianID, pEmpresaID, pUsuarioSolicita, datosDetalle, pfolioFormateado, pURLPolizasSeguroID, pRamoSeguroID, pSolicitud,pSubpolizaID,seleccionTipoPoliza, pSubRamoID } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        URLSolSegFianID = pURLSolSegFianID;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        URLSubPolizaID = pSubpolizaID;
        datosDetalleURL = datosDetalle;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        nRamoSeguroID = pRamoSeguroID;
        nSubRamoID = pSubRamoID;
        nSolicitud = pSolicitud;
        pSeleccionTipoPoliza = seleccionTipoPoliza;
    }
    let { onCerrarModal, onOcultarTabla } = datosDetalleURL;
    mostrarTabla = URLSolSegFianID ? true : false;

    if (datosDetalleURL != undefined && datosDetalleURL.length > 0) {
        lUtilizaProps = false;
    }

    if (lUtilizaProps) {
        let { pSolSegFianID, pEquipoElecID, pEmpresaID, pUsuarioSolicita, onCerrarModal, mostrarTabla, onOcultarTabla, pfolioFormateado, pURLPolizasSeguroID, pEstatus, pRamoSeguroID, pSolicitud, pSubpolizaID,seleccionTipoPoliza, pSubRamoID } = datosDetalle;
        URLSolSegFianID = pSolSegFianID;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        URLSubPolizaID = pSubpolizaID;
        mostrarTabla = mostrarTabla;
        nEquipoElecID = pEquipoElecID;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        cEstatus = pEstatus;
        nRamoSeguroID = pRamoSeguroID;
        nSubRamoID = pSubRamoID;
        nSolicitud = pSolicitud;
        pSeleccionTipoPoliza = seleccionTipoPoliza;
    }
    mostrarTabla = lMostrarFormularioCompleto;

    // let {pEquipoElecID,pSolSegFianID,mostrarTabla,onCerrarModal,onOcultarTabla} = props.datosDetalle;
    const { token } = useSelector(state => state.login);


    const cleanState = {
        equipoElecID: nEquipoElecID,
        solSegFianID: URLSolSegFianID,
        activoAsegID: null,
        infoActAsegID: null,
        equipo: "",
        noEco: "NA",
        nocpu: "NA",
        serieCPU: "NA",
        noMonitor: "NA",
        serieMonitor: "NA",
        serie: "",
        anio: "",
        valorAseg: "",
        empresaID: nEmpresaID,
        ubicacionID: null,
        sustituye: "",
        motivo: "",
        responsable: "",
        sustituyeID: "0",
        omitir: 0,
        divisaID: datosDetalle.codigoMoneda == 'USD' ? 100 : 69,
        paridad: "1",
        departamento: "",
        activo: "ACTIVO",
        modelo: "",
        montoAsegurado: "0",
        marca: ""
    };

    const cleanErrorState =
    {
        equipoElecID: { error: false, helperText: "" },
        solSegFianID: { error: false, helperText: "" },
        activoAsegID: { error: false, helperText: "" },
        infoActAsegID: { error: false, helperText: "" },
        equipo: { error: false, helperText: "" },
        noEco: { error: false, helperText: "" },
        nocpu: { error: false, helperText: "" },
        serieCPU: { error: false, helperText: "" },
        noMonitor: { error: false, helperText: "" },
        serieMonitor: { error: false, helperText: "" },
        serie: { error: false, helperText: "" },
        anio: { error: false, helperText: "" },
        valorAseg: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        ubicacionID: { error: false, helperText: "" },
        sustituye: { error: false, helperText: "" },
        motivo: { error: false, helperText: "" },
        responsable: { error: false, helperText: "" },
        sustituyeID: { error: false, helperText: "" },
        omitir: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        departamento: { error: false, helperText: "" },
        activo: { error: false, helperText: "" },
        modelo: { error: false, helperText: "" },
        montoAsegurado: { error: false, helperText: "" },
        marca: { error: false, helperText: "" }
    };

    const cleanNotificaciones = { mensajes: "", color: null, open: false };


    //Seleccionables
    const [disabledActivo, setDisabledActivo] = useState(false);
    const [seleccionables, setSeleccionables] = useState({});
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(!mostrarTabla);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [sumAsegTotal,setSumAsegTotal] = useState(0);

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    let titleAccion = state.equipoElecID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Equipo Electrónico`;

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    const activoAsegIDRef = useRef(null);
    const infoActAsegIDRef = useRef(null);
    const equipoRef = useRef(null);
    const noEcoRef = useRef(null);
    const nocpuRef = useRef(null);
    const serieCPURef = useRef(null);
    const noMonitorRef = useRef(null);
    const serieMonitorRef = useRef(null);
    const serieRef = useRef(null);
    const anioRef = useRef(null);
    const valorAsegRef = useRef(null);
    const empresaIDRef = useRef(null);
    const ubicacionIDRef = useRef(null);
    const sustituyeRef = useRef(null);
    const motivoRef = useRef(null);
    const responsableRef = useRef(null);
    const sustituyeIDRef = useRef(null);
    const omitirRef = useRef(null);
    const divisaIDRef = useRef(null);
    const departamentoRef = useRef(null);
    const modeloRef = useRef(null);
    const montoAseguradoRef = useRef(null);
    const marcaRef = useRef(null);

    let acciones = [
        {
            accionID: [1, 3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "equipoElecID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getSolSegFianEquipoElec, formatearResultado: [
                    { id: "activo", formato: (data) => data === null ? "" : data },
                ]
            })
        },
        {
            accionID: [4],
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "equipoElecID" },
            { campo: "equipo", variable: "equipo" }],
            disabled: { multiseleccion: true },
            onClick: ({ equipoElecID, equipo }) => handelAlertEliminar({
                setAlert,
                mensaje: `El equipo electrónico ${equipo}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { equipoElecID },
                    asyncDelete: deleteSolSegFianEquipoElec
                })
            })
        },
        {
            accionID: [7],
            icono: UnarchiveIcon,
            color: info,
            descripcion: "Adjuntar",
            parametros: [{ campo: "equipoElecID" }],
            disabled: { multiseleccion: true },
            onClick: ({ equipoElecID }) => {
                //  setRedireccionarFormulario({ equipoElecID });
                setDatosDetalleAdjuntos({
                    pURLDoctoOrigenID: equipoElecID,
                    pTipo: cfolioFormateado,
                    pClasificacion: "",
                    pTabla: "Solicitud Póliza de Equipo Electrónico",
                    pTitulo2: cTitulo2,
                    plUtilizaProps: true
                });
                setOpenDocumentacion(true);
            }
        }
    ];

    let botones = [{
        icono: BackspaceOutlined,
        color: danger,
        descripcion: "Regresar",
        disabled: { multiseleccion: true },
        onClick: () => {
            //  history.push(`/gen/SolicitudesSeguros/`);
            setOpenSolSegEquipoElectrico(false);
        }
    },
    {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }
    ];

    if (cEstatus != "RECHAZADA"){
        botones = [...botones, {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {
                setOpen(true);
                divisas()
            },
            disabled: { multiseleccion: true }
        }]
    }


    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "equipoElecID",
        color: primary,
        title: "Equipo Electronico",
        title2: cTitulo2,
        title3: `Suma total: $${ ftFormatoImportes( redondeo( parseFloat(toFixed(sumAsegTotal,2)),2 ) )}`,
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "equipoElecID", descripcion: "ID ELECTRICO", hide: true },
            { variable: "equipo", descripcion: "Equipo", hide: false },
            { variable: "marca", descripcion: "Marca", hide: false },
            { variable: "noEco", descripcion: "Número de Equipo", hide: false },
            { variable: "nocpu", descripcion: "Número de CPU", hide: false },
            { variable: "modelo", descripcion: "Modelo", hide: false },
            { variable: "serieCPU", descripcion: "Número de Serie CPU", hide: false },
            { variable: "noMonitor", descripcion: "Número de Monitor", hide: false },
            { variable: "serieMonitor", descripcion: "Número de Serie del Monitor", hide: false },
            { variable: "serie", descripcion: "Número de Serie", hide: false },
            { variable: "anio", descripcion: "Año", hide: false },
            { variable: "departamento", descripcion: "Departamento", hide: false },
            //{ variable: "valorAseg", descripcion: "Valor Asegurado", hide: false },
            { variable: "valorAseg", descripcion: "Valor Factura", hide: false },
            { variable: "montoAsegurado", descripcion: "Monto Asegurado", hide: false },
            { variable: "divisa", descripcion: "Divisa", hide: false },
            { variable: "responsable", descripcion: "Responsable", hide: false },
            { variable: "activoSustituye", descripcion: nSolicitud === "Baja de Seguro" ? "Baja a" : "Activo a Sustituir", hide: false },
           // { variable: "motivo", descripcion: "Motivo", hide: false },
        ],
        formato: [
            {
                columnas: ["valorAseg","montoAsegurado"], tipo: "moneda"
            },
        ],
        responsiveTitle: ["equipo", "noEco"],
        all: (datos) => {
            let suma = 0;
            (Array.isArray(datos) && datos.length > 0) && datos.forEach(({ valorAseg }) => {
                suma += valorAseg;
            })
            setSumAsegTotal(suma);
        },
    }
    
    const inputs = [
        {
            disabled: InputsDisabled(),
            id: "activoAsegID",
            value: state.activoAsegID,
            error: errorState.activoAsegID.error,
            success: state.activoAsegID && !errorState.activoAsegID.error ? true : undefined,
            helperText: errorState.activoAsegID.helperText,
            inputRef: activoAsegIDRef,
            title: "Activo Asegurado",
            placeholder: "Seleccione el Activo Asegurado",
            tipo: "autocomplete",
            data: seleccionables.ActivoAseg,
            grid: { md: 4, lg: 4 },
            onChange: (valor) => { setState({ ...state, activoAsegID: valor ? valor.value : '', activoaseg: valor ? valor.label : '' }) }
        },
        {
            disabled: true,
           // disabled: disabledActivo === true ? true : (state.empresaID ? true : false),
            id: "empresaID",
            value: state.empresaID,
            error: errorState.empresaID.error,
            success: state.empresaID && !errorState.empresaID.error ? true : undefined,
            helperText: errorState.empresaID.helperText,
            inputRef: empresaIDRef,
            title: "Empresa*",
            placeholder: "Seleccione la empresa",
            tipo: "autocomplete",
            data: seleccionables.Empresa,
            grid: { md: 4, lg: 4 },
            onChange: ({ value, label }) => { setState({ ...state, empresaID: value, nombre: label }) }
        },
        {
            disabled: InputsDisabled(),
            id: "ubicacionID",
            value: state.ubicacionID,
            error: errorState.ubicacionID.error,
            success: state.ubicacionID && !errorState.ubicacionID.error ? true : undefined,
            helperText: errorState.ubicacionID.helperText,
            inputRef: ubicacionIDRef,
            title: "Ubicación de la empresa *",
            placeholder: "Seleccione la ubicación de la empresa",
            tipo: "autocomplete",
            data: seleccionables.Ubicaciones,
            grid: { md: 4, lg: 4 },
            onChange: ({ value, label }) => { setState({ ...state, ubicacionID: value, ubicacion: label }) }
        },
        {
            disabled: InputsDisabled(),
            id: "equipo",
            value: state.equipo,
            error: errorState.equipo.error,
            success: state.equipo && !errorState.equipo.error ? true : undefined,
            helperText: errorState.equipo.helperText,
            inputRef: equipoRef,
            title: "Equipo *",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca la equipo",
            onChange: ({ target: { value } }) => handleChange(value, "equipo"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "marca",
            value: state.marca,
            error: errorState.marca.error,
            success: state.marca && !errorState.marca.error ? true : undefined,
            helperText: errorState.marca.helperText,
            inputRef: marcaRef,
            title: "Marca *",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca la marca",
            onChange: ({ target: { value } }) => handleChange(value, "marca"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "noEco",
            value: state.noEco,
            error: errorState.noEco.error,
            success: state.noEco && !errorState.noEco.error ? true : undefined,
            helperText: errorState.noEco.helperText,
            inputRef: noEcoRef,
            title: "Número de equipo *",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca el número del equipo",
            onChange: ({ target: { value } }) => handleChange(value, "noEco"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "nocpu",
            value: state.nocpu,
            error: errorState.nocpu.error,
            success: state.nocpu && !errorState.nocpu.error ? true : undefined,
            helperText: errorState.nocpu.helperText,
            inputRef: nocpuRef,
            title: "Número de CPU *",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca el número del CPU",
            onChange: ({ target: { value } }) => handleChange(value, "nocpu"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "modelo",
            value: state.modelo,
            error: errorState.modelo.error,
            success: state.modelo && !errorState.modelo.error ? true : undefined,
            helperText: errorState.modelo.helperText,
            inputRef: modeloRef,
            title: "Modelo",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca el modelo",
            onChange: ({ target: { value } }) => handleChange(value, "modelo"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "anio",
            value: state.anio,
            error: errorState.anio.error,
            success: state.anio && !errorState.anio.error ? true : undefined,
            helperText: errorState.anio.helperText,
            inputRef: anioRef,
            title: "Año *",
            textAlign: "right",
            grid: { md: 2, lg: 2 },
            placeholder: "Introduzca el año",
            onChange: ({ target: { value } }) =>{ rgxNumeros.test(value) && handleChange(value, "anio")},
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "serie",
            value: state.serie,
            error: errorState.serie.error,
            success: state.serie && !errorState.serie.error ? true : undefined,
            helperText: errorState.serie.helperText,
            inputRef: serieRef,
            title: "Número de Serie *",
            grid: { md: 3, lg: 3 },
            placeholder: "Introduzca el número de serie",
            onChange: ({ target: { value } }) => handleChange(value, "serie"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "serieCPU",
            value: state.serieCPU,
            error: errorState.serieCPU.error,
            success: state.serieCPU && !errorState.serieCPU.error ? true : undefined,
            helperText: errorState.serieCPU.helperText,
            inputRef: serieCPURef,
            title: "Número de serie del CPU *",
            grid: { md: 4, lg: 4 },
            placeholder: "Introduzca el número de serie del equipo",
            onChange: ({ target: { value } }) => handleChange(value, "serieCPU"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "noMonitor",
            value: state.noMonitor,
            error: errorState.noMonitor.error,
            success: state.noMonitor && !errorState.noMonitor.error ? true : undefined,
            helperText: errorState.noMonitor.helperText,
            inputRef: noMonitorRef,
            title: "Número del monitor *",
            grid: { md: 4, lg: 4 },
            placeholder: "Introduzca el número del monitor",
            onChange: ({ target: { value } }) => handleChange(value, "noMonitor"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "serieMonitor",
            value: state.serieMonitor,
            error: errorState.serieMonitor.error,
            success: state.serieMonitor && !errorState.serieMonitor.error ? true : undefined,
            helperText: errorState.serieMonitor.helperText,
            inputRef: serieMonitorRef,
            title: "Número de serie del monitor *",
            grid: { md: 4, lg: 4 },
            placeholder: "Introduzca el número de serie del monitor",
            onChange: ({ target: { value } }) => handleChange(value, "serieMonitor"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "departamento",
            value: state.departamento,
            error: errorState.departamento.error,
            success: state.departamento && !errorState.departamento.error ? true : undefined,
            helperText: errorState.departamento.helperText,
            inputRef: departamentoRef,
            title: "Departamento *",
            grid: { md: 4, lg: 4 },
            placeholder: "Introduzca el nombre de la persona",
            onChange: ({ target: { value } }) => handleChange(value, "departamento"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "valorAseg",
            value: state.valorAseg,
            error: errorState.valorAseg.error,
            success: state.valorAseg && !errorState.valorAseg.error ? true : undefined,
            helperText: errorState.valorAseg.helperText,
            inputRef: valorAsegRef,
            title: "Valor Factura *",
            icono: <IconoSimbolo simbolo="$" />,
            formatoImportes: true,
            placeholder: "0.0",
            posicionIcono: "start",
            textAlign: "right",
            grid: { md: 4, lg: 4 },
            placeholder: "Introduzca el valor",
            onChange: ({ target: { value } }) => {
                (!isNaN(Number(value)) && value !=='' ) &&
                    setState({...state,
                        valorAseg: value, montoAsegurado: redondeo( parseFloat(toFixed((parseFloat(value)/parseFloat(state.paridad) ),2) ),2 )}) 
            },
            onKeyDown: () => { return; }
        },
        {
            disabled: true,
            id: "divisaID",
            value: state.divisaID,
            error: errorState.divisaID.error,
            success: Array.isArray(state.divisaID) && state.divisaID.length > 0 && state.divisaID && !errorState.divisaID.error ? true : undefined,
            helperText: errorState.divisaID.helperText,
            inputRef: divisaIDRef,
            title: "Divisa",
            placeholder: "Seleccionar la divisa",
            grid: { md: 2, lg: 2 },
            tipo: "select",
            data: listDivisas,
            onChange: ({ target: { value } }) => 
            handleChangeDivisa(value, "divisaID"),
        },
        {
            disabled: state.divisaID === 69,
            id: "paridad",
            value: state.paridad,
            error: errorState.paridad.error,
            success: state.paridad && !errorState.paridad.error ? true : undefined,
            helperText: errorState.paridad.helperText,
            title: "Paridad Actual",
            textAlign: "right",
            placeholder: "Paridad Actual",
            grid: { md: 2, lg: 2 },
            onChange: ({ target: { value } }) => { (!isNaN(Number(value)) && value.trim() !=='') &&
                setState({
                    ...state,
                    paridad: value, montoAsegurado: redondeo(parseFloat(toFixed(parseFloat((state.valorAseg) / parseFloat(value)), 2)), 2)
                })
            }
        },
        {
            disabled,
            id: "montoAsegurado",
            value: state.montoAsegurado,
            error: errorState.montoAsegurado.error,
            success: state.montoAsegurado && !errorState.montoAsegurado.error ? true :
                undefined,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            helperText: errorState.montoAsegurado.helperText,
            inputRef: montoAseguradoRef,
            title: "Valor Asegurado",
            textAlign: "right",
            placeholder: "Especifique el valor",
            grid: { md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { (rgxDecimal14.test(value)) && handleChange(value, "montoAsegurado") },
            onKeyDown: () => { return; }
        },{
            disabled: InputsDisabled(),
            id: "sustituye",
            value: state.sustituye,
            error: errorState.sustituye.error ,
            success: state.sustituye && !errorState.sustituye.error ? true: undefined,
            helperText: errorState.sustituye.helperText,
            inputRef: sustituyeRef,
            title: "Sustituye A",
            grid: {md:4, lg:4},
            placeholder: "Introduzca el nombre del equipo al que se sustituye",
            onChange: ({target: {value}}) => handleChange(value, "sustituye") ,
            onKeyDown: () => {return;}
        },
        {
            disabled: disabledActivo === true ? true : false,
            id: "sustituyeID",
            value: state.sustituyeID,
            error: errorState.sustituyeID.error,
            success: state.sustituyeID && !errorState.sustituyeID.error ? true : undefined,
            helperText: errorState.sustituyeID.helperText,
            inputRef: sustituyeIDRef,
            title: nSolicitud === "Baja de Seguro" ? "Activo a dar de Baja *" : "Activo a Sustituir *",
            placeholder: "Seleccione Activo a Sustituir",
            tipo: "autocomplete",
            data: seleccionables.Activos,
            grid: { md: 6, lg: 6 },
            onChange: ({ value, label }) => { handleChangeSustituyeID(value, nSolicitud) }
        },
        {
            disabled: disabledActivo === true ? true : false,
            id: "activo",
            value: state.activo,
            error: errorState.activo.error,
            success: Array.isArray(state.activo) && state.activo.length > 0 && state.activo && !errorState.activo.error ? true : undefined,
            helperText: errorState.activo.helperText,
            title: "Estado",
            placeholder: "Seleccionar el estado",
            grid: { md: 2, lg: 2 },
            tipo: "select",
            data: [{
                label: 'ACTIVO',
                value: 'ACTIVO'
            },
            {
                label: 'INACTIVO',
                value: 'INACTIVO'
            }],
            onChange: ({ target: { value } }) => handleChange(value, "activo"),
        },
        {
            disabled: disabledActivo === true ? true : false,
            id: "motivo",
            value: state.motivo,
            error: errorState.motivo.error,
            success: state.motivo && !errorState.motivo.error ? true : undefined,
            helperText: errorState.motivo.helperText,
            inputRef: motivoRef,
            title: "Motivo",
            grid: { md: 5, lg: 5 },
            placeholder: nSolicitud === "Baja de Seguro" ? "Introduza el motivo por el cual se dara de Baja" : "Introduza el motivo por el cual se sustituye",
            onChange: ({ target: { value } }) => handleChange(value, "motivo"),
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "responsable",
            value: state.responsable,
            error: errorState.responsable.error,
            success: state.responsable && !errorState.responsable.error ? true : undefined,
            helperText: errorState.responsable.helperText,
            inputRef: responsableRef,
            title: "Responsable",
            grid: { md: 5, lg: 5 },
            placeholder: "Introduzca el nombre de la persona responsable",
            onChange: ({ target: { value } }) => handleChange(value, "responsable"),
            onKeyDown: () => { return; }
        },
        {
            disabled:true,
            id: "omitir",
            value: state.omitir,
            checked: state.omitir,
            title: "Omitir monto en pólizas",
            tipo: "switch",
            grid: { md: 2, lg: 2 },
            onChange: (e) => {
                setState({ ...state, omitir: (e.target.checked ? 1 : 0) })
            }
        },

    ];


    function InputsDisabled() {
        if (nSolicitud === "Baja de Seguro"){
                return true;
        }else{
            if(disabledActivo === true){
                return true;
            }
                return false;
        }
    }

    let accionesFormulario = [{
        accionID: state.equipoElecID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: equipoRef,
            mensajeCorrecto: `El equipo se ${state.equipoElecID ? "actualizo" : "agrego"} correctamente `,
            asyncCallback: ftGuardar
        })
    },
    {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }
    ];

    //FUNCION PARA MANEJAR LOS ERRORES AL INTRODUCIR TEXTO
    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }


    async function handleChangeDivisa (value, key) {
        const valorAseg = parseFloat(state.valorAseg);
        if (!isNaN(valorAseg)) {
            if (URLPolizasSeguroID && (parseInt(URLPolizasSeguroID) > 0)) {
                let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
                if (datosPolizaSeguro?.paridad) {
                    setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad, montoAsegurado: redondeo( parseFloat( toFixed( (parseFloat(state.monto)/parseFloat(datosPolizaSeguro.paridad)),2 ) ),2 )});
                }
            } else {
                let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
                if (historialParidades?.length > 0) {            
                    setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad,montoAsegurado: redondeo( parseFloat( toFixed( (valorAseg/parseFloat(historialParidades[0].paridad)),2 ) ),2 )});
                }
            }
        }
    }

    //FUNCION PARA REALIZAR LAS VALIDACIONES
    async function handleValidaciones({ activoAsegID, equipo, noEco, nocpu, serieCPU, noMonitor, serieMonitor, serie, anio, valorAseg, empresaID, ubicacionID, sustituye, motivo, responsable, departamento,modelo, marca, sustituyeID}) {

        let error;
        let validaciones = [
            {
                condicion: !activoAsegID,
                keyError: "activoAsegID",
                mensajeError: "Debe especificar el activo asegurar"
            }, {
                condicion: !empresaID,
                keyError: "empresaID",
                mensajeError: "Debe especificar la empresa"
            }, {
                condicion: !ubicacionID,
                keyError: "ubicacionID",
                mensajeError: "Debe especificar la ubicación"
            },
            {
                condicion: !equipo || equipo.trim() === "",
                ref: equipoRef, keyError: "equipo",
                mensajeError: "Debe especificar el equipo"
            },
            {
                condicion: !marca || marca.trim() === "",
                ref: marcaRef, keyError: "marca",
                mensajeError: "Debe especificar el marca"
            },
            {
                condicion: !noEco || noEco.trim() === "",
                ref: noEcoRef, keyError: "noEco",
                mensajeError: "Debe especificar el número económico del equipo"
            },
            /* 
            {
                condicion: !modelo || modelo.trim() === "",
                ref: modeloRef, keyError: "modelo",
                mensajeError: "Debe especificar el modelo"
            },
            {
                condicion: !nocpu,
                ref: nocpuRef, keyError: "nocpu",
                mensajeError: "Debe especificar el numero de CPU del equipo"
            },
            {
                condicion: !serieCPU ,
                ref: serieCPURef, keyError: "serieCPU",
                mensajeError: "Debe especificar el número de serie del equipo"
            },

            {
                condicion: !noMonitor ,
                ref: noMonitorRef, keyError: "noMonitor",
                mensajeError: "Debe especificar el nombre del equipo"
            },
            {
                condicion: !serieMonitor ,
                ref: serieMonitorRef, keyError: "serieMonitor",
                mensajeError: "Debe especificar el numero de serie del monitor"
            },
            */
            {
                condicion: !serie || serie.trim() === "",
                ref: serieRef, keyError: "serie",
                mensajeError: "Debe especificar el número de serie del equipo"
            },
            {
                condicion: !departamento || departamento.trim() === "",
                ref: departamentoRef, keyError: "departamento",
                mensajeError: "Debe especificar el departamento"
            },
            {
                condicion: !anio,
                ref: anioRef, keyError: "anio",
                mensajeError: "Debe especificar el año del equipo"
            },
            /*
            {
                condicion: !valorAseg,
                ref: valorAsegRef, keyError: "valorAseg",
                mensajeError: "Debe especificar el valor asegurado"
            },
            {
                condicion: !motivo ,
                ref: motivoRef, keyError: "motivo",
                mensajeError: "Debe especificar el motivo"
            },
            */
            {
                condicion: !sustituyeID,
                keyError: "sustituyeID",
                mensajeError: "Debe especificar el activo a sustituir"
            },
            {
                condicion: !responsable || responsable.trim() ==="",
                ref: responsableRef, keyError: "responsable",
                mensajeError: "Debe especificar el responsable"
            }
        ];
        
        
        if(nSolicitud === "Baja de Seguro"){

            validaciones = [];
        }

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }


    function ftGuardar() {
        async function ftGuardar() {
            let { equipoElecID, solSegFianID, activoAsegID, equipo, noEco, nocpu, serieCPU, noMonitor, serieMonitor, serie, anio, valorAseg, empresaID, ubicacionID, sustituye, motivo, responsable, sustituyeID, omitir, divisaID, paridad, departamento, activo, modelo, montoAsegurado, marca } = state;


            let error = await handleValidaciones({ activoAsegID, equipo, noEco, nocpu, serieCPU, noMonitor, serieMonitor, serie, anio, valorAseg, empresaID, ubicacionID, sustituye, motivo, responsable, departamento, modelo, marca, sustituyeID });

            if (error) return error;

            if(nSolicitud === "Baja de Seguro"){
                //equipoElecID = equipoElecID === "" ? 0 : parseInt(equipoElecID) 
                solSegFianID = solSegFianID === "" ? 0 : parseInt(solSegFianID) 
                activoAsegID = (activoAsegID === "" || activoAsegID === null) ? 0 : parseInt(activoAsegID)
                equipo = equipo === "" ? "-" : equipo
                noEco = noEco === "" ? "-" : noEco
                nocpu = nocpu === "" ? "-" : nocpu
                serieCPU = serieCPU === "" ? "-" : serieCPU
                noMonitor = noMonitor === "" ? "-" : noMonitor
                serieMonitor = serieMonitor === "" ? "-" : serieMonitor
                serie = serie === "" ? "-" : serie 
                anio = anio === "" ? 0 : parseInt(anio) 
                valorAseg = valorAseg === "" ? 0 : parseFloat(valorAseg)
                empresaID = (empresaID === "" || empresaID === null) ? 0 : parseInt(empresaID) 
                ubicacionID = (ubicacionID === "" || ubicacionID === null) ? 0 : parseInt(ubicacionID) 
                sustituye = sustituye === "" ? "-" : sustituye
                motivo = motivo === "" ? "-" : motivo 
                responsable = responsable === "" ? "-" : responsable
                sustituyeID = (sustituyeID === "" || sustituyeID === null) ? 0 : parseInt(sustituyeID) 
                omitir = omitir === "" ? 0 : parseInt(omitir)  
                divisaID = divisaID === "" ? 0 : parseInt(divisaID) 
                paridad = paridad === "" ? 0 : parseFloat(paridad)
                departamento = departamento === "" ? "-" : departamento 
                activo = activo === "" ? "-" : activo
                modelo = modelo === "" ? "-" : modelo 
                marca = marca === "" ? "-" : marca 
            }

            if (equipoElecID) await updateSolSegFianEquipoElec({
                input:
                    { equipoElecID, 
                    solSegFianID, 
                    activoAsegID, 
                    equipo: trim(equipo), 
                    noEco: trim(noEco), 
                    nocpu: trim(nocpu), 
                    serieCPU: trim(serieCPU), 
                    noMonitor: trim(noMonitor), 
                    serieMonitor: trim(serieMonitor),
                    serie: trim(serie), 
                    anio: parseInt(anio, 10), 
                    valorAseg: parseFloat(valorAseg), 
                    empresaID, 
                    ubicacionID, 
                    sustituye: trim(sustituye), 
                    motivo: trim(motivo), 
                    responsable: trim(responsable), 
                    sustituyeID: parseInt(sustituyeID), 
                    omitir: parseInt(omitir), 
                    divisaID, 
                    paridad: paridad === undefined ? null : (isNaN(paridad) ? null : parseFloat(paridad)),
                    departamento: trim(departamento),
                    activo: trim(activo), 
                    modelo,
                    montoAsegurado: parseFloat(montoAsegurado),
                    marca: trim(marca)
                }
            }, token);
            else await addSolSegFianEquipoElec({
                input:
                {
                    solSegFianID, activoAsegID, equipo: trim(equipo), noEco: trim(noEco), nocpu: trim(nocpu), serieCPU: trim(serieCPU), noMonitor: trim(noMonitor), serieMonitor: trim(serieMonitor), serie: trim(serie), anio: parseInt(anio, 10), valorAseg: parseFloat(valorAseg), empresaID, ubicacionID, sustituye: trim(sustituye), motivo: trim(motivo), responsable: trim(responsable), sustituyeID: parseInt(sustituyeID), omitir: parseInt(omitir), divisaID, paridad: paridad === undefined ? null : (paridad === "" ? null : paridad), departamento: trim(departamento),activo: trim(activo),modelo,montoAsegurado: parseFloat(montoAsegurado), marca: trim(marca)
                }
            }, token);

        }
        return ftGuardar();
    }



    //FUNCION QUE PERMITE MANEJAR EL CERRADO DEL FORMULARIO MODAL
    //AL FINALIZAR EL REGISTRO DE DATOS
    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        if (!mostrarTabla) {
            if (onCerrarModal != undefined) { onCerrarModal(); }
        } else {
            // if (onCerrarModal!= undefined) {onCerrarModal();}
            //  if (onOcultarTabla!= undefined) {onOcultarTabla();}
        }
    }

    //FUNCION PARA MANEJAR LOS CAMBIOS
    function handleChange(value, key) {
        setState({ ...state, [key]: value }); 
    }


    function setRedireccionarFormulario({ equipoElecID }) {

        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${equipoElecID}", "pTipo": "${cfolioFormateado}", "pTabla": "Solicitud Póliza de Equipo Electrónico", "pTitulo2": "${cTitulo2}" }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }


    async function divisas(){
        if (URLPolizasSeguroID && Number(URLPolizasSeguroID) !== 0) {
           let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
           setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
        } else {
            let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: state.divisaID } }, token);        
            setState({ ...state, ["paridad"]: historialParidades[0].paridad });
        }
    }


    async function handleChangeSustituyeID (value, nSolicitud) {
                   
        
        //  setState({ ...state, ["sustituyeID"]: value});
        if (Number(value) > 0){

            let res = await getInfoActAsegPolizas({ infoActAsegID: value}, token) 

            setDisabledActivo(true);
            setDisabled(true);
            if (nSolicitud === "Baja de Seguro" ){
               setState({ ...state, 
              ["sustituyeID"]: value === undefined ? "" : value, 
              ["activoAsegID"]: res.activoAsegID === undefined ? "" :  res.activoAsegID,
              ["activo"]: res.estado === undefined ? "" :  res.estado,
              ["equipo"]: res.equipo === undefined ? "" : res.equipo, 
              ["marca"]: res.marca === undefined ? "" : res.marca, 
              ["noEco"]: res.noEco === undefined ? "" : res.noEco, 
              ["nocpu"]: res.nocpu === undefined ? "" : res.nocpu, 
              ["modelo"]: res.modelo === undefined ? "" : res.modelo, 
              ["serieCPU"]: res.serieCPU === undefined ? "" :  res.serieCPU, 
              ["noMonitor"]: res.noMonitor === undefined ? "" : res.noMonitor, 
              ["serieMonitor"]: res.serieMonitor === undefined ? "" :  res.serieMonitor,
              ["serie"]: res.serie === undefined ? "" : res.serie,
              ["departamento"]: res.departamento === undefined ? "" : res.departamento,
              ["anio"]: res.anio === undefined ? "" : res.anio, 
              ["valorAseg"]: res.valorFactura === undefined ? "" : res.valorFactura,
              ["sustituye"]: res.sustituye === undefined ? "" : res.sustituye,
              ["divisaID"]: res.divisaID === undefined ? "" : res.divisaID, 
              ["paridad"]: res.paridad === undefined ? "" : res.paridad,
              ["responsable"]: res.responsable === undefined ? "" : res.responsable,
              ["ubicacionID"]: res.ubicacionID === undefined ? "" : res.ubicacionID });
            }else{
               setState({ ...state, ["sustituyeID"]: value, ["activo"]: res.estado });
           }
            setDisabledActivo(false);
            setDisabled(false);
        }else{
             setState({ ...state, ["sustituyeID"]: value});
        }

    }



    useEffect(() => {
        async function ftEffect() {
            try {
                //let ActivoAseg = await seleccionActivosAsegurado({ filtro: { clasificacion: "Equipo Electrónico" } }, token);
                let ActivoAseg = await seleccionActivosAsegurado({ filtro: { clasificacion: "Equipo Electronico" } }, token);
                let Empresa = await seleccionEmpresa({ filtro: { empresaID: nEmpresaID } }, token);
                let Ubicaciones = await seleccionUbicaciones({ filtro: { empresaID: nEmpresaID } }, token);

                let Activos;
                
                if (pSeleccionTipoPoliza) {
                    if (pSeleccionTipoPoliza == 2) {
                        if (parseInt(URLSubPolizaID) > 0) {
                            Activos = await seleccionActivosAseguradosSolPol({ filtro: {polizasSeguroID: parseInt(URLPolizasSeguroID) ,subPolizaID: URLSubPolizaID == 0 ? null : parseInt(URLSubPolizaID)}}, token, nSolicitud, "Equipo Electrónico" );
                        } else {
                            Activos = await seleccionActivosAseguradosSolPol({ filtro: {empresaID: nEmpresaID !== "" ? parseInt(nEmpresaID) : -1, ramoSeguroID: nSubRamoID !== "" ? parseInt(nSubRamoID) : -1, } }, token, nSolicitud, "Equipo Electrónico" );
                        }
                    }
                    else {
                        if (parseInt(URLPolizasSeguroID) > 0) {
                            Activos = await seleccionActivosAseguradosSolPol(
                                { 
                                    filtro: {
                                        subPolizaID: URLSubPolizaID == 0 ? null : parseInt(URLSubPolizaID)
                                    } 
                                },
                                token,
                                nSolicitud, 
                                "Equipo Electrónico" 
                            );
                        } else {
                            Activos = await seleccionActivosAseguradosSolPol(
                                { 
                                    filtro: {
                                        empresaID: nEmpresaID !== "" ? parseInt(nEmpresaID) : -1,
                                        ramoSeguroID: nSubRamoID !== "" ? parseInt(nSubRamoID) : -1
                                    } 
                                },
                                token,
                                nSolicitud,
                                "Equipo Electrónico" 
                            );
                        }
                    }
                } else {
                    Activos = await seleccionActivosAseguradosSolPol(
                        {
                            filtro: {
                                empresaID: nEmpresaID == "" || nEmpresaID == 0 || nEmpresaID == null ? null : parseInt(nEmpresaID),
                                ramoSeguroID: Number(nRamoSeguroID),
                            }
                        },
                        token,
                        nSolicitud,
                        "Equipo Electrónico"  
                    );
                }


                setSeleccionables({ ActivoAseg, Empresa, Ubicaciones, Activos });
            }
            catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    //INFORMACION A MOSTRAR
    return (<>
        {mostrarTabla && <Tabla
            infoTabla={infoTabla}
            asyncData={tablaSolSegFianEquipoElec}
            asyncSumaActivos={getAllSolSegFianEquipoElec}
            token={token}
            parametrosFijos={{ solSegFianID: URLSolSegFianID }}
        />}
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotificaciones={() => setNotificaciones({
                ...cleanNotificaciones
            })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={equipoRef}
        />
        {
            openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default SolSegFianEquipoElec;
